import dayjs from 'dayjs';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import {
  mabUserNameApostrophesRegex,
  mabUserNameExcludedRegex,
  mabUserNameRegex,
  mabUserNameRepeatedRegex,
  mabUserPhoneNumberRegex
} from '../../../helpers/regex';
import { DATE_FORMAT } from '../../../helpers/constants';

export const applyAdditionalRules = ({ type, currentValidation, rulesToApply, extraInputData }) => {
  if (!additionalRules[type] || !ObjectShim.isObject(rulesToApply)) {
    return currentValidation;
  }
  return Object.keys(rulesToApply).reduce((rule, key) => {
    if (additionalRules[type][key]) {
      return rule.test({
        name: type,
        message: rulesToApply[key].errorMessage,
        test: value => {
          if (!value) {
            return true;
          }
          try {
            const data = rulesToApply[key];
            return additionalRules[type][key](value, { ...data, ...extraInputData });
          } catch {
            return true;
          }
        }
      });
    }
    return rule;
  }, currentValidation);
};

const additionalRules = {
  date: {
    'date-of-birth': (value, { limit = 16, format = DATE_FORMAT }) => {
      const date = dayjs(value, format);
      const today = dayjs();

      return today.diff(date, 'year') >= limit;
    },
    'date-before-today': (value, { format = DATE_FORMAT }) => {
      const date = dayjs(value, format);
      const today = dayjs().subtract(1, 'day');

      return date.isBefore(today);
    },
    'date-after-today': (value, { format = DATE_FORMAT }) => {
      const date = dayjs(value, format);
      const today = dayjs();

      return date.isAfter(today);
    },
    'date-in-one-year-from-today': (value, { format = DATE_FORMAT }) => {
      const date = dayjs(value.toString(), format);
      const today = dayjs();
      const oneYearFromToday = today.add(1, 'year');

      return date.isAfter(today) && date.isBefore(oneYearFromToday);
    }
  },
  phoneNumber: {
    'mab-phone-number': value => mabUserPhoneNumberRegex.test(value)
  },
  text: {
    'mab-user-name': value => {
      const userNameMatch = mabUserNameRegex.test(value);
      const userNameApostrophesMatch = mabUserNameApostrophesRegex.test(value);
      const userNameExcludedMatch = mabUserNameExcludedRegex.test(value);
      const userNameRepeatedMatch = mabUserNameRepeatedRegex.test(value);

      return userNameMatch && !userNameApostrophesMatch && !userNameExcludedMatch && !userNameRepeatedMatch;
    }
  }
};
