import React from 'react';
import { InputWithButton as Input } from './components/input-with-button';

const maskDefault = '+99 99999 999999 999999';
const maskCharDefault = ' ';

export const QuestionPhoneNumberInput = React.memo(
  ({
    format,
    label,
    value,
    mask = maskDefault,
    maskChar = maskCharDefault,
    onChange,
    onError,
    onSubmit,
    loading,
    disabled,
    buttonLabel,
    buttonType,
    placeholder,
    preSubmitButton,
    additionalRules,
    explainer,
    isOptional,
    informer,
    error
  }) => (
    <>
      <Input
        type='tel'
        placeholder={placeholder}
        inputType='phoneNumber'
        label={label}
        format={format}
        value={value}
        errorMessage={error}
        mask={mask}
        maskChar={maskChar}
        onError={onError}
        onChange={onChange}
        onSubmit={onSubmit}
        loading={loading}
        disabled={disabled}
        buttonLabel={buttonLabel}
        buttonType={buttonType}
        preSubmitButton={preSubmitButton}
        additionalRules={additionalRules}
        explainer={explainer}
        isOptional={isOptional}
        advancedInformer={informer}
      />
    </>
  )
);
