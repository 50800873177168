import { useContext } from 'react';
import { QuestionContext } from '../index';

export const useQuestionContext = () => {
  const context = useContext(QuestionContext);
  if (context === undefined) {
    throw new Error('useConditionBuilderContext must be used within a ConditionBuilderProvider');
  }

  return context;
};
