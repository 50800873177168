import { isUndefined } from '@packages/helpers/core/common';
import { StringShim } from '@packages/helpers/core/shims/string-shim';
import { getUserAttributeTypeParts, isInputNested } from './nested-attribute';

// eslint-disable-next-line import/no-webpack-loader-syntax
import * as workerBase from 'workerize-loader!../../../helpers/workers/evaluate-additional-values';

const worker = workerBase();

const parseValues = async (values, params = {}) => {
  const acc = {};

  for (const key of Object.keys(values)) {
    //if value is not valid - it will not be added
    try {
      const value = values[key];
      // No deep parsing for objects and arrays for now
      const evaluated = StringShim.isString(value) ? await worker.evaluate(value, params) : value;

      if (!isUndefined(evaluated)) {
        acc[key] = evaluated;
      }
    } catch {}
  }

  return acc;
};

export const processAdditionalValues = async (userAttributes, value, extraParams) => {
  try {
    const normalized = value.reduce((acc, item) => {
      if (!acc[item.userAttributeType]) {
        acc[item.userAttributeType] = item;
      } else {
        acc[item.userAttributeType].userAttributeValues = {
          ...acc[item.userAttributeType].userAttributeValues,
          ...item.userAttributeValues
        };
      }

      return acc;
    }, {});

    const { identifier } = extraParams;

    const params = { userAttributes, ...extraParams };
    const acc = {};
    for (const key of Object.keys(normalized)) {
      const { name } = getUserAttributeTypeParts(key);
      if (isInputNested(name, identifier)) {
        const defaultKey = `${key}.${identifier}`;

        acc[defaultKey] = await parseValues(normalized[key].userAttributeValues, params);
        continue;
      }
      acc[key] = await parseValues(normalized[key].userAttributeValues, params);
    }

    return acc;
  } catch {
    return false;
  }
};
