import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { branch, compose, renderNothing, withPropsOnChange } from 'recompose';
import { pickProps } from '../../../../helpers/hocs/pick-props';
import styles from '../../../../styles/components/page-header/identifier.module.scss';
import { STYLING_OPTIONS } from '../../../../helpers/pages/constants';
import { ConfigService } from '../../../../services/config-service';
import { withDisplayCondition } from '../../../../templates/checklist/with-display-condition';

const Component = React.memo(({ text, styling, className }) => (
  <div className={classnames(styles.category, className, { [styles[styling]]: !!styling })}>{text}</div>
));

const mapStateToProps = ({ application: { pageHeader } }) => {
  const { currentPageConfigName } = pageHeader;

  return { currentPageConfigName };
};

export const Identifier = compose(
  connect(mapStateToProps),
  withPropsOnChange(
    ['identifier', 'category', 'pageConfigKey', 'currentPageConfigName'],
    ({ identifier, category, currentPageConfigName }) => {
      const text = identifier?.text || category;
      const styling = identifier?.styling || null;
      const displayCondition = identifier?.displayCondition;
      const hideIdentifier = ConfigService.get(`${currentPageConfigName}.pageHeader.hideIdentifier`, false);

      const show = !hideIdentifier && text;

      return { text, styling, show, displayCondition };
    }
  ),
  withDisplayCondition,
  branch(({ show }) => !show, renderNothing),
  pickProps(['text', 'styling', 'className'])
)(Component);

Identifier.displayName = 'Identifier';

export const IdentifierConfigPropTypes = {
  text: PropTypes.string,
  styling: PropTypes.oneOf([null, ...Object.values(STYLING_OPTIONS)])
};

Identifier.propTypes = {
  ...IdentifierConfigPropTypes,
  category: PropTypes.string,
  className: PropTypes.string,
  pageConfigKey: PropTypes.string
};
