import React, { useCallback } from 'react';
import { componentFromProp, compose, mapProps, renderNothing } from 'recompose';
import { INPUT_TYPES } from '../helpers/constants';
import { withInputHandlers } from '../hocs/with-handlers';
import { withLabelCondition } from '../../../templates/checklist/with-label-condition';
import { QuestionTextInput } from './text';
import { QuestionButtonInput } from './button';
import { QuestionNumberInput } from './number';
import { QuestionPercentInput } from './percent';
import { QuestionAddressInput } from './address';
import { QuestionDateInput } from './date';
import { QuestionWheelInput } from './wheel';
import { QuestionTextareaInput } from './textarea';
import { QuestionCheckboxInput } from './checkbox';
import { QuestionRadioInput } from './radio';
import { QuestionPensionProviderInput } from './pension-provider';
import { QuestionEmailInput } from './email';
import { QuestionPhoneNumberInput } from './phone-number';
import { QuestionNiNInput } from './nin';
import { QuestionSelectInput } from './select';
import { QuestionPropertyAddressInput } from './property-address';
import { QuestionInfoButton } from './button-info';
import { QuestionGroupInput } from './group';
import { QuestionDatasetInput } from './dataset';
import { QuestionIntroducerAutoCompleteInput } from './dataset-with-introducers';
import { QuestionIntroducerInput } from './introducer';
import { QuestionSegmentedButton } from './segmented-button';
import { QuestionPriorityGoalsInput } from './priority-goals';
import { withContainer } from './components/input-field';
import { QuestionUpdateUserInput } from './update-user';

export const Input = compose(
  mapProps(({ type, data, ...props }) => ({
    ...props,
    ...data,
    type,
    component: components[type] ?? renderNothing
  })),
  withLabelCondition,
  withInputHandlers
)(componentFromProp('component'));

export const PureGroupInput = React.memo(props => {
  const renderChild = useCallback(({ key, type, data, ...rest }) => {
    return <Input {...rest} key={key} type={type} data={data} />;
  }, []);

  return (
    <QuestionGroupInput {...props} value={null}>
      {renderChild}
    </QuestionGroupInput>
  );
});

const GroupInput = compose(withContainer)(PureGroupInput);

const components = {
  [INPUT_TYPES.button]: QuestionButtonInput,
  [INPUT_TYPES.text]: QuestionTextInput,
  [INPUT_TYPES.number]: QuestionNumberInput,
  [INPUT_TYPES.percent]: QuestionPercentInput,
  [INPUT_TYPES.address]: QuestionAddressInput,
  [INPUT_TYPES.propertyAddress]: QuestionPropertyAddressInput,
  [INPUT_TYPES.date]: QuestionDateInput,
  [INPUT_TYPES.wheel]: QuestionWheelInput,
  [INPUT_TYPES.textarea]: QuestionTextareaInput,
  [INPUT_TYPES.checkbox]: QuestionCheckboxInput,
  [INPUT_TYPES.radio]: QuestionRadioInput,
  [INPUT_TYPES.pensionProvider]: QuestionPensionProviderInput,
  [INPUT_TYPES.email]: QuestionEmailInput,
  [INPUT_TYPES.phoneNumber]: QuestionPhoneNumberInput,
  [INPUT_TYPES.nin]: QuestionNiNInput,
  [INPUT_TYPES.select]: QuestionSelectInput,
  [INPUT_TYPES.infoButton]: QuestionInfoButton,
  [INPUT_TYPES.groupParams]: GroupInput,
  [INPUT_TYPES.groupParamsMarketingList]: GroupInput,
  [INPUT_TYPES.dataset]: QuestionDatasetInput,
  [INPUT_TYPES.segmentedButton]: QuestionSegmentedButton,
  [INPUT_TYPES.updateUser]: QuestionUpdateUserInput,
  [INPUT_TYPES.introducer]: QuestionIntroducerInput,
  [INPUT_TYPES.introducerAutocomplete]: QuestionIntroducerAutoCompleteInput,
  [INPUT_TYPES.priorityGoalSelect]: QuestionPriorityGoalsInput
};
