import { compose, withState, withHandlers, withProps, withPropsOnChange } from 'recompose';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { evaluateStringCondition } from '../../helpers/checklist/display-condition';
import { omitProps } from '../../helpers/hocs/omit-props';
import { withDisplayConditionState } from '../../helpers/display-condition/hocs/with-display-condition-state';

export const withInputRenderCondition = compose(
  withDisplayConditionState,
  withHandlers({
    updateItems:
      ({ submitValue, items, setFormValues, setFormDisabledItems, setFormErrorItems, userState }) =>
      () => {
        if (!items) {
          return [];
        }

        const updatedItemsPromises = items.map(item => {
          if (!item.data.displayCondition) {
            return item;
          }

          const updatedUserState = ObjectShim.mergeDeep(userState, submitValue);

          const conditionIsTrue = evaluateStringCondition(item.data.displayCondition, updatedUserState);

          if (!conditionIsTrue) {
            const itemPathPayload = {
              attributeType: item.data.userAttributeType,
              userAttributeField: item.data.userAttributeField
            };
            setFormDisabledItems(itemPathPayload)(false);
            setFormErrorItems(itemPathPayload)(null);
            setFormValues(itemPathPayload)(null);
          }

          return conditionIsTrue && item;
        });

        return updatedItemsPromises.filter(Boolean);
      }
  }),
  withState('updatedItems', 'setUpdatedItems', ({ updateItems }) => updateItems()),
  withPropsOnChange(['submitValue'], ({ updateItems, setUpdatedItems }) => setUpdatedItems(updateItems())),
  withProps(({ updatedItems }) => ({ items: updatedItems })),
  omitProps('updatedItems')
);
