import React from 'react';
import PropTypes from 'prop-types';
import { SafeArea } from '../../../components/layout/safe-area';
import { PurifiedHtmlContainer } from '../../../components/layout/purified-html-container';
import { withStoryBlockWrapper } from './hocs/with-story-block-wrapper';

const Component = ({ heading, text }) => (
  <SafeArea edges={['bottom']} fixed timeout={250}>
    <div className='fill-height typography typography-extended'>
      {heading && <h2>{heading}</h2>}
      {text && <PurifiedHtmlContainer renderAs='div' html={text} />}
    </div>
  </SafeArea>
);

const WordsComponent = withStoryBlockWrapper(Component);

WordsComponent.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string
};

export { WordsComponent };
