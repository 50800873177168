import { createQueryParams } from '../helpers/main';
import { get } from './utils';
import { CONTENT } from './utils/constants';

export const getKnowledgeBase = async () => await get('/v2/knowledgebase', CONTENT);
export const getKnowledgeFAQ = async ({ name, module }) => {
  const params = {
    name,
    module
  };

  return await get(`/v2/faq-accordion?${createQueryParams(params)}`, CONTENT);
};
export const getCustomPage = async ({ name, trigger }) =>
  await get(`/v2/custom-page?name=${name}&trigger=${trigger}`, CONTENT);
export const getBlogContent = async (page = 0) => await get(`/v2/blog?page=${page}`, CONTENT);
export const getBlogPostBySlug = async slug => await get(`/v2/blog/${slug}`, CONTENT);
export const getProjectPostBySlug = async slug => await get(`/v2/project/${slug}`, CONTENT);
export const getArticleBySlug = async ({ slug, clean = true }) =>
  await get(`/v2/knowledgebase/article?slug=${slug}&clean=${clean}`, CONTENT);
export const getArticlesBySlug = async ({ slugs, clean = true }) =>
  await get(`/v2/knowledgebase/articles?slugs=${slugs}&clean=${clean}`, CONTENT);
export const getLegalPostBySlug = async tag => await get(`/v2/help/${tag}`, CONTENT);
export const getLandingPage = async ({ name }) => await get(`/v2/landing-page?name=${name}`, CONTENT);
export const getSEO = async ({ name }) => await get(`/seo/${name}`, CONTENT);
export const getTourPage = async ({ name }) => await get(`/tour/${name}`, CONTENT);
export const getRewardCards = async () => await get('/rewards', CONTENT);
export const getRewardPage = async slug => await get(`/rewards/${slug}`, CONTENT);

export const content = {
  getKnowledgeBase,
  getKnowledgeFAQ,
  getCustomPage,
  getLegalPostBySlug,
  getBlogContent,
  getBlogPostBySlug,
  getArticleBySlug,
  getArticlesBySlug,
  getLandingPage,
  getSEO,
  getTourPage,
  getRewardCards,
  getRewardPage,
  getProjectPostBySlug
};
