import { LocalStorageService } from '@packages/helpers/core/services/local-storage';
import { UserService } from './user-service';

export class TrackingService {
  static store = new LocalStorageService('Trackers');

  static getTimestamp() {
    return Date.now();
  }

  static setTrackerTimestamp(tracker) {
    return TrackingService.store.set(store => ({
      ...store,
      [tracker]: TrackingService.getTimestamp()
    }));
  }

  static getTrackerTimestamp(tracker) {
    return TrackingService.store.get()[tracker];
  }

  static buildTrackingData(tracker, data) {
    return {
      event: { name: tracker, timestamp: this.getTimestamp(), ...data },
      context: { visitorId: UserService.user.userId }
    };
  }
}
