import { matchPath } from 'react-router-dom';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { ArrayShim } from '@packages/helpers/core/shims/array-shim';
import { getAdvertStoreGroupName } from '../../helpers/advert/advert';
import { createReducer, createAction } from '../utils';

const GET_KNOWLEDGE_BASE_TOPICS_REQUEST = 'GET_KNOWLEDGE_BASE_TOPICS_REQUEST';
export const GET_KNOWLEDGE_BASE_TOPICS_SUCCESS = 'GET_KNOWLEDGE_BASE_TOPICS_SUCCESS';
const GET_KNOWLEDGE_BASE_TOPICS_FAILURE = 'GET_KNOWLEDGE_BASE_TOPICS_FAILURE';

const GET_CUSTOM_PAGE_REQUEST = 'GET_CUSTOM_PAGE_REQUEST';
const GET_CUSTOM_PAGE_SUCCESS = 'GET_CUSTOM_PAGE_SUCCESS';
export const GET_CUSTOM_PAGE_FAILURE = 'GET_CUSTOM_PAGE_FAILURE';
const RESET_CUSTOM_PAGE = 'RESET_CUSTOM_PAGE';
const SKIP_CUSTOM_PAGE_REQUEST = 'SKIP_CUSTOM_PAGE_REQUEST';

const GET_BLOG_CONTENT_REQUEST = 'GET_BLOG_CONTENT_REQUEST';
const GET_BLOG_CONTENT_SUCCESS = 'GET_BLOG_CONTENT_SUCCESS';
const GET_BLOG_CONTENT_FAILURE = 'GET_BLOG_CONTENT_FAILURE';

const GET_CONTENT_PAGE_REQUEST = 'GET_CONTENT_PAGE_REQUEST';
const GET_CONTENT_PAGE_SUCCESS = 'GET_CONTENT_PAGE_SUCCESS';
const GET_CONTENT_PAGE_FAILURE = 'GET_CONTENT_PAGE_FAILURE';

const GET_CONTENT_PAGES_REQUEST = 'GET_CONTENT_PAGES_REQUEST';
const GET_CONTENT_PAGES_SUCCESS = 'GET_CONTENT_PAGES_SUCCESS';
const GET_CONTENT_PAGES_FAILURE = 'GET_CONTENT_PAGES_FAILURE';

const GET_LEGAL_PAGE_REQUEST = 'GET_LEGAL_PAGE_REQUEST';
const GET_LEGAL_PAGE_SUCCESS = 'GET_LEGAL_PAGE_SUCCESS';
const GET_LEGAL_PAGE_FAILURE = 'GET_LEGAL_PAGE_FAILURE';

const GET_LANDING_PAGE_REQUEST = 'GET_LANDING_PAGE_REQUEST';
const GET_LANDING_PAGE_SUCCESS = 'GET_LANDING_PAGE_SUCCESS';
const GET_LANDING_PAGE_FAILURE = 'GET_LANDING_PAGE_FAILURE';

const GET_SEO_PAGE_DATA_REQUEST = 'GET_SEO_PAGE_DATA_REQUEST';
const GET_SEO_PAGE_DATA_SUCCESS = 'GET_SEO_PAGE_DATA_SUCCESS';
const GET_SEO_PAGE_DATA_FAILURE = 'GET_SEO_PAGE_DATA_FAILURE';

const GET_TOUR_PAGE_REQUEST = 'GET_TOUR_PAGE_REQUEST';
const GET_TOUR_PAGE_SUCCESS = 'GET_TOUR_PAGE_SUCCESS';
const GET_TOUR_PAGE_FAILURE = 'GET_TOUR_PAGE_FAILURE';

const GET_ADVERTS_REQUEST = 'GET_ADVERTS_REQUEST';
const GET_ADVERTS_SUCCESS = 'GET_ADVERTS_SUCCESS';
const GET_ADVERTS_FAILURE = 'GET_ADVERTS_FAILURE';

const GET_STORIES_REQUEST = 'GET_STORIES_REQUEST';
const GET_STORIES_SUCCESS = 'GET_STORIES_SUCCESS';
const GET_STORIES_FAILURE = 'GET_STORIES_FAILURE';

const GET_VIDEO_REQUEST = 'GET_VIDEO_REQUEST';
const GET_VIDEO_SUCCESS = 'GET_VIDEO_SUCCESS';
const GET_VIDEO_FAILURE = 'GET_VIDEO_FAILURE';

export const getKnowledgeBase = params =>
  createAction(
    {
      request: GET_KNOWLEDGE_BASE_TOPICS_REQUEST,
      success: GET_KNOWLEDGE_BASE_TOPICS_SUCCESS,
      failure: GET_KNOWLEDGE_BASE_TOPICS_FAILURE
    },
    api => api.content.getKnowledgeBase
  )(params);

export const getCustomPage = params => async (dispatch, getState) => {
  const fromDonePage = !!matchPath(params.from, '/checklist/:module/done/:redirectTo?');
  const wasSkipped = getState().content.skipCustomPageRequest;

  if (!fromDonePage && wasSkipped) {
    dispatch({ type: SKIP_CUSTOM_PAGE_REQUEST, skip: false });

    return getState().content.customPages[params.full] ?? {};
  }

  const response = await dispatch(
    createAction(
      {
        request: GET_CUSTOM_PAGE_REQUEST,
        success: GET_CUSTOM_PAGE_SUCCESS,
        failure: GET_CUSTOM_PAGE_FAILURE
      },
      api => api.content.getCustomPage
    )(params)
  );

  if (fromDonePage && !wasSkipped) {
    dispatch({ type: SKIP_CUSTOM_PAGE_REQUEST, skip: true });
  }

  return response;
};

export const resetCustomPage = params => ({ type: RESET_CUSTOM_PAGE, params });

export const getBlogContent = params =>
  createAction(
    {
      request: GET_BLOG_CONTENT_REQUEST,
      success: GET_BLOG_CONTENT_SUCCESS,
      failure: GET_BLOG_CONTENT_FAILURE
    },
    api => api.content.getBlogContent
  )(params);

export const getBlogPost = params =>
  createAction(
    {
      request: GET_CONTENT_PAGE_REQUEST,
      success: GET_CONTENT_PAGE_SUCCESS,
      failure: GET_CONTENT_PAGE_FAILURE
    },
    api => api.content.getBlogPostBySlug
  )(params);

export const getArticle = params =>
  createAction(
    {
      request: GET_CONTENT_PAGE_REQUEST,
      success: GET_CONTENT_PAGE_SUCCESS,
      failure: GET_CONTENT_PAGE_FAILURE
    },
    api => api.content.getArticleBySlug
  )(params);

export const getProject = params =>
  createAction(
    {
      request: GET_CONTENT_PAGE_REQUEST,
      success: GET_CONTENT_PAGE_SUCCESS,
      failure: GET_CONTENT_PAGE_FAILURE
    },
    api => api.content.getProjectPostBySlug
  )(params);

export const getArticles = params =>
  createAction(
    {
      request: GET_CONTENT_PAGES_REQUEST,
      success: GET_CONTENT_PAGES_SUCCESS,
      failure: GET_CONTENT_PAGES_FAILURE
    },
    api => api.content.getArticlesBySlug
  )(params);

export const getLegalPost = params =>
  createAction(
    {
      request: GET_LEGAL_PAGE_REQUEST,
      success: GET_LEGAL_PAGE_SUCCESS,
      failure: GET_LEGAL_PAGE_FAILURE
    },
    api => api.content.getLegalPostBySlug
  )(params);

export const getLandingPage = params =>
  createAction(
    {
      request: GET_LANDING_PAGE_REQUEST,
      success: GET_LANDING_PAGE_SUCCESS,
      failure: GET_LANDING_PAGE_FAILURE
    },
    api => api.content.getLandingPage
  )(params);

export const getSEOPageData = params =>
  createAction(
    {
      request: GET_SEO_PAGE_DATA_REQUEST,
      success: GET_SEO_PAGE_DATA_SUCCESS,
      failure: GET_SEO_PAGE_DATA_FAILURE
    },
    api => api.content.getSEO
  )(params);

export const getTourPage = params =>
  createAction(
    {
      request: GET_TOUR_PAGE_REQUEST,
      success: GET_TOUR_PAGE_SUCCESS,
      failure: GET_TOUR_PAGE_FAILURE
    },
    api => api.content.getTourPage
  )(params);

export const getAdverts = params =>
  createAction(
    {
      request: GET_ADVERTS_REQUEST,
      success: GET_ADVERTS_SUCCESS,
      failure: GET_ADVERTS_FAILURE
    },
    api => api.users.getAdvertsByGroup
  )(params);

export const getStory = slug =>
  createAction(
    {
      request: GET_STORIES_REQUEST,
      success: GET_STORIES_SUCCESS,
      failure: GET_STORIES_FAILURE
    },
    api => api.userContent.getStory
  )(slug);

export const getVideo = slug =>
  createAction(
    {
      request: GET_VIDEO_REQUEST,
      success: GET_VIDEO_SUCCESS,
      failure: GET_VIDEO_FAILURE
    },
    api => api.userContent.getVideo
  )(slug);

const initialState = {
  error: false,
  knowledgeTopics: [],
  loadingKnowledgeTopics: true,
  customPages: {},
  loadingCustomPage: true,
  skipCustomPageRequest: false,
  blogContent: {
    posts: [],
    page: 0,
    maxCount: 0
  },
  loadingBlogContent: true,
  contentPages: {},
  loadingContentPage: false,
  legalPages: {},
  loadingLegalPage: false,
  landingPages: {},
  loadingLandingPage: true,
  seoPages: {},
  loadingSeoPage: true,
  tourPages: {},
  loadingTourPage: true,
  adverts: {},
  loadingAdverts: {},
  videos: {},
  loadingVideos: true,
  errorVideos: false,
  stories: {},
  loadingStories: true,
  errorStories: false
};

export const content = createReducer(initialState, {
  [GET_KNOWLEDGE_BASE_TOPICS_REQUEST]: state => ({ ...state, loadingKnowledgeTopics: true }),
  [GET_KNOWLEDGE_BASE_TOPICS_SUCCESS]: (state, action) => ({
    ...state,
    knowledgeTopics: action.payload,
    loadingKnowledgeTopics: false
  }),
  [GET_KNOWLEDGE_BASE_TOPICS_FAILURE]: state => ({ ...state, loadingKnowledgeTopics: false, error: true }),
  [GET_CUSTOM_PAGE_REQUEST]: state => ({
    ...state,
    loadingCustomPage: true,
    error: false
  }),
  [GET_CUSTOM_PAGE_SUCCESS]: (state, { params, payload }) => ({
    ...state,
    customPages: { ...state.customPages, [params.full]: payload },
    loadingCustomPage: false,
    error: false
  }),
  [GET_CUSTOM_PAGE_FAILURE]: (state, { params }) => ({
    ...state,
    customPages: { ...state.customPages, [params.full]: {} },
    loadingCustomPage: false,
    error: true
  }),
  [RESET_CUSTOM_PAGE]: (state, { params }) => ({
    ...state,
    customPages: ObjectShim.omit(state.customPages, [params.full]),
    loadingCustomPage: true,
    error: false
  }),
  [SKIP_CUSTOM_PAGE_REQUEST]: (state, { skip }) => ({
    ...state,
    skipCustomPageRequest: skip
  }),
  [GET_BLOG_CONTENT_REQUEST]: state => ({ ...state, loadingBlogContent: true }),
  [GET_BLOG_CONTENT_SUCCESS]: (state, action) => ({
    ...state,
    blogContent: {
      posts: [...state.blogContent.posts, ...action.payload.posts],
      page: state.blogContent.page + 1,
      maxCount: action.payload.count
    },
    loadingBlogContent: false
  }),
  [GET_BLOG_CONTENT_FAILURE]: state => ({ ...state, loadingBlogContent: false, error: true }),
  [GET_CONTENT_PAGE_REQUEST]: state => ({ ...state, loadingContentPage: true }),
  [GET_CONTENT_PAGE_SUCCESS]: (state, action) => ({
    ...state,
    contentPages: { ...state.contentPages, [action.payload.slug]: action.payload },
    loadingContentPage: false
  }),
  [GET_CONTENT_PAGE_FAILURE]: state => ({ ...state, loadingContentPage: false, error: true }),
  [GET_CONTENT_PAGES_REQUEST]: state => ({ ...state, loadingContentPage: true }),
  [GET_CONTENT_PAGES_SUCCESS]: (state, action) => ({
    ...state,
    contentPages: { ...state.contentPages, ...ArrayShim.normalize(action.payload, 'slug') },
    loadingContentPage: false
  }),
  [GET_CONTENT_PAGES_FAILURE]: state => ({ ...state, loadingContentPage: false, error: true }),
  [GET_LEGAL_PAGE_REQUEST]: state => ({ ...state, loadingLegalPage: true }),
  [GET_LEGAL_PAGE_SUCCESS]: (state, action) => ({
    ...state,
    legalPages: { ...state.legalPages, [action.payload.pageName]: action.payload },
    loadingLegalPage: false
  }),
  [GET_LEGAL_PAGE_FAILURE]: state => ({ ...state, loadingLegalPage: false, error: true }),
  [GET_LANDING_PAGE_REQUEST]: state => ({ ...state, loadingLandingPage: true }),
  [GET_LANDING_PAGE_SUCCESS]: (state, action) => ({
    ...state,
    landingPages: { ...state.landingPages, [action.params.name]: action.payload },
    loadingLandingPage: false
  }),
  [GET_LANDING_PAGE_FAILURE]: state => ({ ...state, loadingLandingPage: false, error: true }),
  [GET_SEO_PAGE_DATA_REQUEST]: state => ({ ...state, loadingSeoPage: true }),
  [GET_SEO_PAGE_DATA_SUCCESS]: (state, action) => ({
    ...state,
    seoPages: { ...state.seoPages, [action.params.name]: action.payload },
    loadingSeoPage: false
  }),
  [GET_SEO_PAGE_DATA_FAILURE]: state => ({ ...state, loadingSeoPage: false, error: true }),
  [GET_LANDING_PAGE_FAILURE]: state => ({ ...state, loadingLandingPage: false, error: true }),
  [GET_TOUR_PAGE_REQUEST]: state => ({ ...state, loadingTourPage: true }),
  [GET_TOUR_PAGE_SUCCESS]: (state, action) => ({
    ...state,
    loadingTourPage: false,
    tourPages: { ...state.tourPages, [action.params.name]: ArrayShim.normalize(action.payload, 'pageName') }
  }),
  [GET_TOUR_PAGE_FAILURE]: state => ({ ...state, loadingTourPage: false }),
  [GET_ADVERTS_REQUEST]: (state, action) => {
    const groupName = getAdvertStoreGroupName(action.params);

    return { ...state, loadingAdverts: { ...state.loadingAdverts, [groupName]: true } };
  },
  [GET_ADVERTS_SUCCESS]: (state, action) => {
    const groupName = getAdvertStoreGroupName(action.params);

    return {
      ...state,
      loadingAdverts: { ...state.loadingAdverts, [groupName]: false },
      adverts: { ...state.adverts, [groupName]: action.payload }
    };
  },
  [GET_ADVERTS_FAILURE]: (state, action) => {
    const groupName = getAdvertStoreGroupName(action.params);

    return { ...state, loadingAdverts: { ...state.loadingAdverts, [groupName]: false } };
  },
  [GET_STORIES_REQUEST]: state => ({ ...state, loadingStories: true }),
  [GET_STORIES_SUCCESS]: (state, action) => ({
    ...state,
    stories: { ...state.list, [action.payload.slug]: action.payload },
    loadingStories: false,
    errorStories: false
  }),
  [GET_STORIES_REQUEST]: state => ({ ...state, loadingStories: false, errorStories: true }),
  [GET_VIDEO_REQUEST]: state => ({ ...state, loadingVideos: true }),
  [GET_VIDEO_SUCCESS]: (state, action) => ({
    ...state,
    videos: { ...state.list, [action.payload.slug]: action.payload },
    loadingVideos: false,
    errorVideos: false
  }),
  [GET_VIDEO_REQUEST]: state => ({ ...state, loadingVideos: false, errorVideos: true })
});
