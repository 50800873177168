import { generatePlainEnumLikeObject } from '@packages/helpers/core/generators';

export const ActionTypes = generatePlainEnumLikeObject(['ToggleLoading', 'SetValidationSchema']);

export const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.ToggleLoading:
      return {
        ...state,
        loading: action.payload
      };
    case ActionTypes.SetValidationSchema:
      return {
        ...state,
        validationSchema: action.payload
      };
    default:
      return state;
  }
};
