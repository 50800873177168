import React from 'react';
import { QuestionContextProvider } from '../index';

export const withQuestionContextProvider = Component => props => {
  return (
    <QuestionContextProvider>
      <Component {...props} />
    </QuestionContextProvider>
  );
};
