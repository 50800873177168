import React, { useMemo } from 'react';
import { compose } from 'recompose';
import uuid from 'uuid';
import styles from '../../../styles/components/select.module.scss';
import { popper } from '../../../helpers/inputs/select-popover';
import { AffiliateIcon } from '../../icons/affiliate-icon';
import { omitProps } from '../../../helpers/hocs/omit-props';
import { withAnchor, withContainer, withError, withExplainer, withLabel } from '../input-field';
import { withPopover } from '../popovers/list';

const Component = React.memo(
  ({ placeholder, id, popoverId, labelId, value = placeholder, displayValue, expanded, focusedItemId, ...props }) => {
    const selectId = useMemo(() => uuid('select-input-value'), []);
    return (
      <button
        className={styles.field}
        role='combobox'
        aria-controls={popoverId}
        aria-haspopup='listbox'
        aria-expanded={expanded}
        id={id}
        aria-atomic='true'
        aria-live='polite'
        aria-activedescendant={expanded ? focusedItemId : undefined}
        aria-describedby={selectId}
        aria-labelledby={`${labelId} ${selectId}`}
        {...props}
      >
        <span id={selectId} className={styles.value}>
          {displayValue || value}
        </span>
        <AffiliateIcon className={styles.icon} name='select-indicator' inline />
      </button>
    );
  }
);

const CONSTRAINS = ['buttonType', 'buttonLabel', 'ariaValue', 'isOptional', 'toggleModal', 'informerHtml'];

export const Select = compose(withPopover({ popper }), omitProps(CONSTRAINS))(Component);
export const withSelectLabel = withLabel({ className: styles.label, layout: styles.container });
export const InputSelect = compose(withAnchor, withContainer, withExplainer, withError, withSelectLabel)(Select);
