import { parse, parseUrl, stringifyUrl } from 'query-string';
import { FunctionShim } from '@packages/helpers/core/shims/function-shim';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { EDIT_MODE } from './index';

export const exclude =
  (keys = []) =>
  params => {
    return ObjectShim.omit(params, keys);
  };

export const pick =
  (keys = []) =>
  params => {
    return ObjectShim.pick(params, keys);
  };

export const withQueryParams = (pathname = '', params = {}, transform) => {
  let { url, query } = parseUrl(pathname);

  query = { ...query, ...params };

  return stringifyUrl(
    {
      url,
      query: FunctionShim.isFunction(transform) ? transform(query) : query
    },
    { skipEmptyString: true }
  );
};

export const withSearchParams = (pathname, search = window.location.search, transform) => {
  return withQueryParams(pathname, parse(search), transform);
};

export const editMode = (pathname, mode = EDIT_MODE) => {
  return withQueryParams(pathname, { mode });
};

export const fromMode = (url, from = window.location.pathname) => toHistoryArgs(url, { from });

/**
 * @typedef {Object} URL
 * @property {string} pathname
 * @property {string} search
 */

/**
 * Extracts pathname and search from url
 * @param {string | URL} url
 * @return {{pathname: string, search: string}}
 */
export const extractPathnameAndSearchFromUrl = url => {
  if (ObjectShim.isObject(url)) {
    return { pathname: url.pathname, search: url.search };
  }

  const [pathname, search] = url.split('?');

  return { pathname, search };
};

export const toHistoryArgs = (url, state) => {
  const { pathname, search } = extractPathnameAndSearchFromUrl(url);

  return { pathname, search, state };
};

export const getLocationFromModeState = (state = window.history.state?.state) => {
  return state?.from ?? null;
};

export const getLocationPropertyGoalState = (state = window.history.state?.state) => {
  return state?.fromPropertyGoalWidget ?? null;
};

export const getShareUrl = externalUrl =>
  externalUrl ? `${externalUrl}${window.location.pathname}` : window.location.href;
