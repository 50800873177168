import * as yup from 'yup';
import { getInputStateIdentifier } from './shemas-v2';

const yupPathToStateIdentifies = path => {
  const names = path.split('.');
  return getInputStateIdentifier(...names);
};

export const getOnChangeValidationError = ({ schema, path, value, context }) => {
  try {
    schema.validateSyncAt(path, value, { context });

    return null;
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      return err.message;
    }
    throw err;
  }
};

export const getOnSubmitValidationError = ({ schema, context, value }) => {
  try {
    schema.validateSync(value, { context, abortEarly: false });
    return {};
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      return err.inner.reduce((acc, { path, message }) => {
        const statePath = yupPathToStateIdentifies(path);

        return { ...acc, [statePath]: message };
      }, {});
    }
    throw err;
  }
};
