import React from 'react';
import { InputWithButton as Input } from './components/input-with-button';

export const QuestionTextInput = React.memo(
  ({
    label,
    format,
    value,
    error,
    onChange,
    onError,
    onSubmit,
    loading,
    disabled,
    buttonLabel,
    buttonType,
    placeholder,
    preSubmitButton,
    additionalRules,
    explainer,
    isRequired,
    isOptional,
    informer
  }) => (
    <Input
      type='text'
      placeholder={placeholder}
      inputType='text'
      label={label}
      format={format}
      value={value}
      errorMessage={error}
      onChange={onChange}
      onError={onError}
      onSubmit={onSubmit}
      loading={loading}
      disabled={disabled}
      buttonLabel={buttonLabel}
      buttonType={buttonType}
      preSubmitButton={preSubmitButton}
      additionalRules={additionalRules}
      explainer={explainer}
      isRequired={isRequired}
      isOptional={isOptional}
      advancedInformer={informer}
    />
  )
);
