import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { UserService } from '../../../../services/user-service';
import { fromMode, getLocationFromModeState, withSearchParams } from '../../../../helpers/navigation/routing';
import { withQuestionState } from '../with-question-state';
import { withValue } from '../with-value';
import { getStaticData } from '../../../../store/reducers/static-data';
import { updateUser } from '../../../../store/reducers/account/actions';
import { withErrorHandler } from './with-error';
import { withChangeHandler } from './with-on-change-handler';
import { withInputHandler } from './with-on-input-handler';
import { withSubmitHandler } from './with-on-submit-handler';
import { withValidationHandler } from './with-validation';

const withHelpers = withHandlers({
  goToNextQuestion:
    ({ history, location: { search, state }, data: { nextPageLink } }) =>
    () => {
      if (UserService.hasAccount()) {
        return;
      }
      //Prevents skipping questions when user is going to edit results
      const origin = getLocationFromModeState(state);

      history.push(fromMode(withSearchParams(nextPageLink, search), origin));
    }
});

export const withInputHandlers = compose(
  withQuestionState,
  withValidationHandler,
  withErrorHandler,
  withValue,
  withChangeHandler,
  withInputHandler
);

export const withSubmitHandlers = compose(
  connect(({ userAttributes }) => ({ userAttributes }), { getStaticData, updateUser }),
  withHelpers,
  withSubmitHandler
);
