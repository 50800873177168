import { connect } from 'react-redux';
import { getNestedAttributePrevState, getUserAttributeTypeParts } from '../helpers/nested-attribute';

const mapStateToProps = (state, ownProps) => {
  const { userAttributeType, identifier } = ownProps;
  const { userAttributes, account } = state;

  const { attributeType, name } = getUserAttributeTypeParts(userAttributeType);

  return {
    userAttributes,
    previousState: getNestedAttributePrevState({ userAttributeType, identifier }, state.userAttributes),
    attributeType,
    accountData: account,
    name
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { userAttributes, attributeType, accountData } = stateProps;

  return { ...dispatchProps, ...ownProps, attributeType, userAttributes, accountData };
};

/**
 * HOC to handle the state for the question input
 */
export const withQuestionState = connect(mapStateToProps, null, mergeProps);
