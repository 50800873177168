import { withHandlers } from 'recompose';
import { mapHandlerByType } from '../../helpers/map-handler-by-input-type';
import { INPUT_TYPES } from '../../helpers/constants';
import { stringDateToISO } from '../../../../helpers/time-format';

export const withChangeHandler = withHandlers(props => {
  const { onChange } = props;

  if (onChange) {
    return {
      onChange:
        ({ data, value }) =>
        () =>
          onChange({ data, value })
    };
  }

  const { type, validateValue } = props;

  return mapHandlerByType(
    type,
    'onChange'
  )({
    [INPUT_TYPES.wheel]:
      ({ setValue, setFormValue }) =>
      () =>
      value => {
        validateValue(value);
        setFormValue(value);
        setValue(value);
      },
    [INPUT_TYPES.checkbox]:
      ({ value, setValue, setFormValue, onError }) =>
      ({ name, boundValue, label }, skipErrorSetting = false) =>
      e => {
        const checkboxValue = { ...value, [name]: { checked: e ? e.target.checked : false, value: boundValue, label } };

        const error = validateValue(checkboxValue);

        //Error does not need to be shown when additionalValueOnFalse is configured
        if (!skipErrorSetting) {
          onError(error);
        }

        setFormValue(checkboxValue);
        //Allow setting additionalValueOnFalse for several checkbox options
        setValue(prevVal => ({
          ...prevVal,
          ...checkboxValue
        }));
      },
    [INPUT_TYPES.radio]:
      ({ setValue, setFormValue, setSubmitAdditionalValues }) =>
      ({ name, boundValue, usePlainValue, additionalValues, label }) =>
      e => {
        const fullValue = { [name]: { checked: e ? e.target.checked : false, value: boundValue, label } };
        const value = usePlainValue ? boundValue : fullValue;

        validateValue(value);
        setFormValue(value);
        setValue(value);

        if (additionalValues) {
          setSubmitAdditionalValues(additionalValues);
        }
      },
    [INPUT_TYPES.segmentedButton]:
      ({ setValue, setFormValue, setSubmitAdditionalValues, plainValue }) =>
      ({ value: boundValue, name, label, additionalValues }) => {
        const value = plainValue ? boundValue : { [name]: { checked: true, value: boundValue, label } };

        validateValue(value);
        setFormValue(value);
        setValue(value);

        if (additionalValues) {
          setSubmitAdditionalValues(additionalValues);
        }
      },
    [INPUT_TYPES.select]:
      ({ setValue, setFormValue, setSubmitAdditionalValues }) =>
      () =>
      ({ value, additionalValues }) => {
        validateValue(value);
        setFormValue(value);
        setValue(value);

        if (additionalValues) {
          setSubmitAdditionalValues(additionalValues);
        }
      },
    [INPUT_TYPES.priorityGoalSelect]:
      ({ setValue, setFormValue }) =>
      () =>
      ({ value }) => {
        validateValue(value);
        setFormValue(value);
        setValue(value);
      },
    [INPUT_TYPES.updateUser]:
      ({ setValue, setFormValue }) =>
      ({ setSubmitError }) =>
      value => {
        setSubmitError(null);
        setFormValue(value);
        validateValue(value);
        setValue(value);
      },
    [INPUT_TYPES.introducer]:
      ({ setValue, setFormValue }) =>
      ({ setSubmitError }) =>
      value => {
        setSubmitError(null);
        setFormValue(value);
        validateValue(value);
        setValue(value);
      },
    [INPUT_TYPES.date]:
      ({ setValue, setFormValue, saveISOFormat, format, onError }) =>
      value => {
        const error = validateValue(value);
        onError(error);
        setFormValue(saveISOFormat ? stringDateToISO(value, format) : value);
        setValue(value);
      },
    default:
      ({ setValue, setFormValue, onError }) =>
      value => {
        const error = validateValue(value);
        onError(error);
        setFormValue(value);
        setValue(value);
      }
  });
});
