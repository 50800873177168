import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { isNil } from '@packages/helpers/core/common';
import { connect } from 'react-redux';
import { isoDateToFormatedString, stringDateToISO } from '../../../helpers/time-format';
import { INPUT_TYPES } from '../helpers/constants';
import { parseDefaultValue } from '../helpers/parse-default-value';
import { getNestedAttributeValue } from '../helpers/nested-attribute';

/**
 * This mapExistingValueByType function is used to modify the input value based on the input type
 */
const mapExistingValueByType = ({ existingValue, type, accountData, ...props }) => {
  if (isNil(existingValue)) return;

  switch (type) {
    case INPUT_TYPES.checkbox:
      // if the type is checkbox, we need to map the existing value to the items to exclude values that are not in the items list
      return props.items.reduce((acc, item) => {
        if (existingValue[item.name]) {
          acc[item.name] = existingValue[item.name];
        }

        return acc;
      }, {});
    case INPUT_TYPES.email:
      return accountData.email ?? existingValue;
    case INPUT_TYPES.date:
      return props.saveISOFormat ? isoDateToFormatedString(existingValue, props.format) : existingValue;
    default:
      return existingValue;
  }
};

const mapFormValueByType = (inputVal, { type, ...props }) => {
  if (isNil(inputVal)) return;

  if (type === INPUT_TYPES.date) {
    return props.saveISOFormat ? stringDateToISO(inputVal, props.format) : inputVal;
  }

  return inputVal;
};

const isValueDifferentFromDefault = (value, defaultValue) => {
  return !isNil(value) && value !== defaultValue && value !== 0;
};

const mapStateToProps = ({ userAttributes }, ownProps) => {
  const { userAttributeType, userAttributeField, identifier } = ownProps;

  return {
    existingValue: getNestedAttributeValue({ userAttributeType, userAttributeField, identifier }, userAttributes)
  };
};

/**
 * This HOC is used to set the value of the input and validate it
 */
export const withValue = compose(
  connect(mapStateToProps),
  withState('value', 'setValue', mapExistingValueByType),
  withHandlers({
    setFormValue: ({ setFormValues, ...props }) => setFormValues(props)
  }),
  lifecycle({
    async componentDidMount() {
      const {
        type,
        value,
        identifier,
        setValue,
        userAttributes,
        defaultValue,
        setFormValue,
        validateValue,
        additionalValues,
        setSubmitAdditionalValues,
        onError
      } = this.props;

      let inputVal = value;

      //here we set calculated default value if there is no value in user attributes
      if (isNil(inputVal) && !isNil(defaultValue)) {
        inputVal = await parseDefaultValue(userAttributes, defaultValue, { identifier });

        setValue(inputVal);
      }

      // For inputs, that don't have a value in it - we do not need to set the form value and validate it
      if (
        ![
          INPUT_TYPES.groupParamsMarketingList,
          INPUT_TYPES.groupParams,
          INPUT_TYPES.button,
          INPUT_TYPES.infoButton
        ].includes(type)
      ) {
        setFormValue(mapFormValueByType(inputVal, this.props));
        const error = validateValue(inputVal);

        // If input value on mount is not valid, we need to show the error
        if (error && isValueDifferentFromDefault(inputVal, defaultValue)) {
          onError(error);
        }
      }

      if (additionalValues) {
        setSubmitAdditionalValues(additionalValues);
      }
    }
  })
);
