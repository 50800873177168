import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';

dayjs.extend(customParseFormat);
dayjs.extend(duration);

// Helper function to determine if a value is a duration (P1Y2M2D) or exact date
export const parseDateBoundary = (value, format = 'DD/MM/YYYY') => {
  if (value.startsWith('P') || value.startsWith('-P')) {
    // Treat as ISO 8601 duration (positive or negative)
    const baseDate = dayjs();
    try {
      const offset = dayjs.duration(value);
      return value.startsWith('-') ? baseDate.subtract(offset) : baseDate.add(offset);
    } catch {
      return null; // Invalid duration
    }
  } else {
    // Treat as an exact date
    const parsedDate = dayjs(value, format, true); // Strict parsing
    return parsedDate.isValid() ? parsedDate : null;
  }
};
