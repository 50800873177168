import { compose, withHandlers } from 'recompose';
import { TrackingService } from '../../services/tracking-service';
import { api } from '../../api';

export const withTrackingHandlers = compose(
  withHandlers({
    sendTrackingData: () => (tracker, additionalData) => {
      api.tracking.sendTrackingEvent(TrackingService.buildTrackingData(tracker, additionalData));
    }
  })
);
