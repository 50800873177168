import React from 'react';
import { connect } from 'react-redux';
import { compose, defaultProps } from 'recompose';
import { KnowledgeBase } from '../../templates/knowledge-base';
import { Footer } from '../../components/layout/page-footer';
import { withMetaTags } from '../../components/meta-tags';
import { VISIBILITY_PAGE_TYPES } from '../../helpers/pages/constants';
import { withBarrier } from '../account/pages/barrier/barrier';

const SEO_PAGE_DATA_NAME = 'knowledgebase';

export const ArticleListComponent = () => {
  return (
    <>
      <KnowledgeBase />
      <Footer pageType={VISIBILITY_PAGE_TYPES.knowledgebase} />
    </>
  );
};

const mapStateToProps = state => ({
  metaData: state.content.seoPages[SEO_PAGE_DATA_NAME]
});

export const ArticleList = compose(
  withBarrier,
  connect(mapStateToProps),
  defaultProps({ seoName: SEO_PAGE_DATA_NAME }),
  withMetaTags
)(ArticleListComponent);
