import Loadable from 'react-loadable';
import { asTemplate } from '../../components/layout/with-layout';
import { Loader } from '../../components/with-loader';
import {
  ACCOUNT_BUSINESS_PROFILE,
  ACCOUNT_DELETE_BUSINESS_PROFILE,
  ACCOUNT_HOME,
  ACCOUNT_HOME_OPTION,
  CHECKPOINT
} from '../../helpers/navigation';

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account"*/ '../../pages/account/pages').then(module => module.AccountMain),
  loading: Loader
});

const AccountDetails = Loadable({
  loader: () =>
    import(/* webpackChunkName: "account-details"*/ '../../pages/account/pages/reset-password/change-password').then(
      module => module.ChangePassword
    ),
  loading: Loader
});

const AccountDetailsCompletePage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "account-details-complete-page"*/ '../../pages/account/components/complete-pages').then(
      module => module.AccountDetailsCompletePage
    ),
  loading: Loader
});

const AccountMarketing = Loadable({
  loader: () =>
    import(/* webpackChunkName: "account-details"*/ '../../pages/account/pages/marketing/marketing').then(
      module => module.AccountMarketing
    ),
  loading: Loader
});

const AccountCookies = Loadable({
  loader: () =>
    import(/* webpackChunkName: "account-cookies"*/ '../../pages/account/pages/cookies/cookies').then(
      module => module.AccountCookies
    ),
  loading: Loader
});

const DeleteAccount = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "account-delete-account"*/ '../../pages/account/pages/delete-account/delete-account'
    ).then(module => module.DeleteAccount),
  loading: Loader
});

const DeleteAccountCompletePage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "delete-account-complete-page"*/ '../../pages/account/components/complete-pages').then(
      module => module.DeleteAccountCompletePage
    ),
  loading: Loader
});

const DeleteProfile = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "account-delete-profile"*/ '../../pages/account/pages/delete-profile/delete-profile'
    ).then(module => module.DeleteProfile),
  loading: Loader
});

const DeleteProfileCompletePage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "delete-profile-complete-page"*/ '../../pages/account/components/complete-pages').then(
      module => module.DeleteProfileCompletePage
    ),
  loading: Loader
});

const RequestData = Loadable({
  loader: () =>
    import(/* webpackChunkName: "account-request-data"*/ '../../pages/account/pages/request-data/request-data').then(
      module => module.RequestData
    ),
  loading: Loader
});

const BusinessProfile = Loadable({
  loader: () =>
    import(/* webpackChunkName: "business-profile"*/ '../../pages/account/pages/profile/index').then(
      module => module.BusinessProfile
    ),
  loading: Loader
});

export const routes = [
  {
    to: '/account',
    component: asTemplate(ACCOUNT_HOME)(Account)
  },
  {
    to: '/account/change-password',
    component: asTemplate(ACCOUNT_HOME_OPTION)(AccountDetails)
  },
  {
    to: '/account/change-password/complete',
    component: asTemplate(CHECKPOINT)(AccountDetailsCompletePage)
  },
  {
    to: '/account/marketing',
    component: asTemplate(ACCOUNT_HOME_OPTION)(AccountMarketing)
  },
  {
    to: '/account/user-consents',
    component: asTemplate(ACCOUNT_HOME_OPTION)(AccountCookies)
  },
  {
    to: '/account/delete-account',
    component: asTemplate(ACCOUNT_HOME_OPTION)(DeleteAccount)
  },
  {
    to: '/account/delete-account/complete',
    component: asTemplate(CHECKPOINT)(DeleteAccountCompletePage)
  },
  {
    to: '/account/request-data',
    component: asTemplate(ACCOUNT_HOME_OPTION)(RequestData)
  },
  {
    to: '/account/profile/:userId',
    component: asTemplate(ACCOUNT_BUSINESS_PROFILE)(BusinessProfile)
  },
  {
    to: '/account/delete-profile/complete',
    component: asTemplate(CHECKPOINT)(DeleteProfileCompletePage)
  },
  {
    to: '/account/delete-profile/:userId',
    component: asTemplate(ACCOUNT_DELETE_BUSINESS_PROFILE)(DeleteProfile)
  }
];
