import { withHandlers } from 'recompose';
import { mapHandlerByType } from '../../helpers/map-handler-by-input-type';
import { INPUT_TYPES } from '../../helpers/constants';

export const withInputHandler = withHandlers(props => {
  const { onInput } = props;

  if (onInput) {
    return {
      onInput:
        ({ data, value }) =>
        () =>
          onInput({ data, value })
    };
  }

  const { type } = props;

  return mapHandlerByType(
    type,
    'onInput'
  )({
    [INPUT_TYPES.checkbox]:
      ({ value, setValue, setFormValue }) =>
      name =>
      inputValue => {
        const newVal = {
          ...value,
          [name]: {
            text: inputValue,
            checked: true
          }
        };
        setFormValue(newVal);
        setValue(newVal);
      },
    [INPUT_TYPES.radio]:
      ({ setValue, setFormValue }) =>
      name =>
      inputValue => {
        const newVal = {
          [name]: {
            text: inputValue,
            checked: true
          }
        };
        setFormValue(newVal);
        setValue(newVal);
      }
  });
});
