import React, { createContext, useCallback, useReducer } from 'react';
import { reducer } from './reducer';
import * as QuestionContextActions from './actions';

const initialState = {
  validationSchema: {},
  setValidationSchema: () => {},
  loading: false,
  turnOnLoading: () => {},
  turnOffLoading: () => {}
};
export const QuestionContext = createContext(initialState);

export const QuestionContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const turnOnLoading = useCallback(() => {
    dispatch(QuestionContextActions.toggleLoading(true));
  }, []);

  const turnOffLoading = useCallback(() => {
    dispatch(QuestionContextActions.toggleLoading(false));
  }, []);

  const setValidationSchema = useCallback(schema => {
    dispatch(QuestionContextActions.setValidationSchema(schema));
  }, []);

  return (
    <QuestionContext.Provider
      value={{
        loading: state.loading,
        validationSchema: state.validationSchema,
        setValidationSchema,
        turnOnLoading,
        turnOffLoading
      }}
    >
      {children}
    </QuestionContext.Provider>
  );
};
