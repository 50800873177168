import { ActionTypes } from './reducer';

export const toggleLoading = loading => ({
  type: ActionTypes.ToggleLoading,
  payload: loading
});

export const setValidationSchema = schema => ({
  type: ActionTypes.SetValidationSchema,
  payload: schema
});
