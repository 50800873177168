import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import { getRequiredActivityRedirectLink } from '../../../helpers/app';
import { withHistory } from '../../with-history';
import { withEmbeddedContext } from '../with-embedded-context';

const mapStateToProps = state => ({
  statusData: state.statusData
});

export const withRequiredRedirect = compose(
  connect(mapStateToProps),
  withEmbeddedContext,
  withHistory,
  withRouter,
  withHandlers({
    processRequiredActivityRedirect:
      ({ statusData, history, isEmbedded, location }) =>
      () => {
        const questionLink = getRequiredActivityRedirectLink({ statusData, pathname: location.pathname });

        if (questionLink && !isEmbedded) {
          history.replace(questionLink);
        }
      }
  })
);
