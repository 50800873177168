import { FHC_MODULE_STATUSES } from '../constants';

export const ACCOUNT_TYPES = {
  HTB: 'htbISA',
  LISA: 'lifetimeISA',
  REGULAR: 'regularSavings',
  CASH_ISA: 'cashISA',
  STOCK_AND_SHARES_ISA: 'stockAndSharesISA',
  CURRENT: 'current',
  OTHERS: 'others'
};
export const NO_MORE_ACCOUNTS_KEY = 'noMoreAccounts';

export const UNITY_MUTUAL_LISA_ACTIVITY = 'unityMutualLisa';

export const REQUIRED_PROPS_COUNT_LISA = 6;
export const REQUIRED_PROPS_COUNT_OTHER = 5;

export const ISA_ACCOUNTS = [
  ACCOUNT_TYPES.HTB,
  ACCOUNT_TYPES.LISA,
  ACCOUNT_TYPES.CASH_ISA,
  ACCOUNT_TYPES.STOCK_AND_SHARES_ISA
];
export const getAccountTypes = () => {
  const { ACCOUNT_TYPE_DATA } = window.CONFIG.DEPOSIT_BUILDER;

  return {
    [ACCOUNT_TYPES.HTB]: ACCOUNT_TYPE_DATA.HTB,
    [ACCOUNT_TYPES.LISA]: ACCOUNT_TYPE_DATA.LISA,
    [ACCOUNT_TYPES.REGULAR]: ACCOUNT_TYPE_DATA.REGULAR,
    [ACCOUNT_TYPES.CASH_ISA]: ACCOUNT_TYPE_DATA.CASH_ISA,
    [ACCOUNT_TYPES.STOCK_AND_SHARES_ISA]: ACCOUNT_TYPE_DATA.STOCK_AND_SHARES_ISA,
    [ACCOUNT_TYPES.CURRENT]: ACCOUNT_TYPE_DATA.CURRENT,
    [ACCOUNT_TYPES.OTHERS]: ACCOUNT_TYPE_DATA.OTHERS
  };
};

export const DEFAULT_ACCOUNTS_PAGE_MESSAGE = {
  hasAccounts:
    `<p>Now let's add another other accounts you are using for savings.</p>` +
    '<p>When done click No more accounts to continue</p>',
  noAccounts:
    '<p>Let’s look at how you’re currently saving, so we can compare different approaches.</p>\n' +
    '<p>Which type of account do you primarily used to save your deposit?<p/>'
};

export const isLisa = type =>
  [ACCOUNT_TYPES.HTB, ACCOUNT_TYPES.LISA, ACCOUNT_TYPES.CASH_ISA, ACCOUNT_TYPES.STOCK_AND_SHARES_ISA].includes(type);

export const getAccountTitleByType = type => {
  const title = getAccountTypes()[type];

  return type === 'regularSavings' ? 'Regular' : title;
};

export const getAccountTitle = account =>
  ACCOUNT_TYPES.OTHERS === account.type && account.name ? account.name : getAccountTitleByType(account.type);

export const monthDiffFromStartingTaxYear = date => {
  const thisYearStartTaxYear = new Date(new Date().getFullYear(), 3, 6);
  const lastSixOfApril =
    thisYearStartTaxYear < Date.now() ? thisYearStartTaxYear : new Date(new Date().getFullYear() - 1, 3, 6);
  let months;
  months = (date.getFullYear() - lastSixOfApril.getFullYear()) * 12;
  months -= lastSixOfApril.getMonth();
  months += date.getMonth();
  months = date.getDate() <= lastSixOfApril.getDate() ? (months -= 1) : months;

  return months <= 0 ? 1 : months;
};

export const monthsDiffWording = n => {
  const arr = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven'];

  return arr[n];
};

export const hasAccountOld = accounts => {
  const keys = Object.keys(accounts);

  if (!accounts || !keys.length || (keys.length === 1 && keys[0] === 'items')) {
    return false;
  }

  let hasAccounts = false;
  for (let i = 0; i < keys.length; i++) {
    if (accounts[keys[i]] && accounts[keys[i]].length > 0) {
      hasAccounts = true;
      break;
    }
  }

  return hasAccounts;
};

export const hasAccount = accounts => purifyAccounts(Object.values(accounts)).length > 0;

export const findAccountByType = (accounts, typeName) => accounts?.find(({ type }) => type === typeName);

export const hasLisaAccount = accounts => accounts && accounts.some(({ type }) => type === ACCOUNT_TYPES.LISA);
export const hasHtbAccount = accounts => accounts && accounts.some(({ type }) => type === ACCOUNT_TYPES.HTB);

export const getLisaStatus = ({ existing, alternative }) => {
  if (hasLisaAccount(existing)) {
    return FHC_MODULE_STATUSES.ALREADY_HAVE_ONE;
  }

  if (hasLisaAccount(alternative)) {
    return FHC_MODULE_STATUSES.SEEN;
  }

  return FHC_MODULE_STATUSES.NOT_APPLICABLE;
};

export const purifyAccounts = accounts =>
  // eslint-disable-next-line
  accounts.filter(item => {
    if (item) {
      if (isLisa(item.type) && Object.keys(item).length === REQUIRED_PROPS_COUNT_LISA) {
        return item;
      } else if (Object.keys(item).length === REQUIRED_PROPS_COUNT_OTHER) {
        return item;
      }
    }
  });
