import { withHandlers } from 'recompose';
import { parse } from 'query-string';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { GEO_LOCATION_FORMATTED_ADDRESS_ENGLAND } from '../../../../helpers/constants';
import { changeLastPartOfPath } from '../../../../helpers/utils';
import { mapHandlerByType } from '../../helpers/map-handler-by-input-type';
import { INPUT_TYPES, INTRODUCER_ATTRIBUTE_TYPE } from '../../helpers/constants';
import { processAdditionalValues } from '../../helpers/parse-additional-values';
import { INTRODUCER_ATTRIBUTE_FIELDS } from '../../../../helpers/account/constants';
import { setGlobalConfig } from '../../../../api/config';
import { pickRadioOptionByValue } from '../../../../helpers/inputs/radio';
import { buildNestedAttributeValue } from '../../helpers/nested-attribute';

export const withSubmitHandler = withHandlers(props => {
  const { onSubmit, turnOnLoading, turnOffLoading } = props;

  if (onSubmit) {
    return {
      onSubmit:
        ({ data, submitValue }) =>
        () =>
          onSubmit({ data, value: submitValue })
    };
  }

  const {
    type,
    history,
    location: { pathname, search },
    data: { nextPageLink }
  } = props;

  const onGoalsLocationSubmit = response => {
    const { goalsLocation, goalsLocationParsed = false } = response;

    const isEngland = goalsLocation === GEO_LOCATION_FORMATTED_ADDRESS_ENGLAND;

    if (isEngland) {
      return history.push(changeLastPartOfPath(pathname, '/region-england'));
    }

    const isParsed = goalsLocationParsed && Object.values(goalsLocationParsed).some(item => item);

    if (isParsed) {
      const mode = search && parse(search).mode;
      const query = mode ? `?mode=${mode}` : '';

      return history.push(nextPageLink + query);
    }

    history.push(changeLastPartOfPath(pathname, '/region'));
  };
  const onLocationSubmit = (response, key) => {
    // API Endpoint is aimed to always return next interface
    // [userAttributeName]: <Object>: <Location>
    // [${userAttributeName}LocationParsed]: <Object>: <ParsedLocation>
    const { [key]: location, [`${key}LocationParsed`]: locationParsed = false } = response;

    const isEngland = location?.formatted_address === GEO_LOCATION_FORMATTED_ADDRESS_ENGLAND;

    if (isEngland) {
      return history.push(changeLastPartOfPath(pathname, '/region-england'));
    }

    const isParsed = locationParsed && Object.values(locationParsed).some(item => item);

    if (isParsed) {
      const mode = search && parse(search).mode;
      const query = mode ? `?mode=${mode}` : '';

      return history.push(nextPageLink + query);
    }

    history.push(changeLastPartOfPath(pathname, '/region'));
  };

  return mapHandlerByType(
    type,
    'onSubmit'
  )({
    [INPUT_TYPES.address]:
      ({ submitValue, data, sendUserAttribute, attributeType, sendStatusUpdate }) =>
      async () => {
        const { userAttributeField } = data;
        turnOnLoading();

        const sendRequest = sendUserAttribute(attributeType);
        const response = await sendRequest(submitValue[attributeType]);

        sendStatusUpdate();

        // TODO: Refactor it on API endpoint readiness.
        return userAttributeField === 'goalsGeolocation'
          ? onGoalsLocationSubmit(response)
          : onLocationSubmit(response, userAttributeField);
      },
    [INPUT_TYPES.button]:
      ({
        identifier,
        data,
        sendUserAttribute,
        sendAdditionalUserAttributes,
        goToNextQuestion,
        userAttributes,
        attributeType,
        sendStatusUpdate
      }) =>
      async () => {
        const {
          userAttributeType,
          userAttributeField,
          userAttributeValue,
          redirectAfterSave,
          additionalValues,
          staticPayload
        } = data;

        const updatedUserAttributes = buildNestedAttributeValue(
          { userAttributeType, userAttributeField, identifier },
          { value: userAttributeValue, staticPayload }
        );

        turnOnLoading();

        const sendMain = () => {
          if (userAttributeField) {
            const sendRequest = sendUserAttribute(attributeType);

            return sendRequest(updatedUserAttributes);
          }
        };

        const sendAdditional = async () => {
          if (additionalValues) {
            const processedValues = await processAdditionalValues(
              ObjectShim.mergeDeep(userAttributes, { [attributeType]: updatedUserAttributes }),
              additionalValues,
              { identifier }
            );
            if (processedValues) {
              return sendAdditionalUserAttributes(ObjectShim.deepen(processedValues), true);
            }
          }
        };

        // redirectAfterSave is mandatory if next question relies on data sent on previous question
        // e.g. pension finder question currently-employed which is right before company-name
        // relies on result of prev question
        if (redirectAfterSave) {
          //waiting for server to respond and redirect after all endpoints are resolved
          await sendMain();
          await sendAdditional();
          await sendStatusUpdate();
        } else {
          //not waiting for server response, optimistic UI approach
          sendMain();
          sendAdditional();
          sendStatusUpdate();
        }

        goToNextQuestion();
      },
    [INPUT_TYPES.groupParamsMarketingList]:
      ({
        identifier,
        submitValue,
        submitAdditionalValues,
        data,
        sendUserAttribute,
        sendUserGroupAttributes,
        sendAdditionalUserAttributes,
        goToNextQuestion,
        userAttributes,
        subscribeUnsubscribeMarketingList,
        sendStatusUpdate
      }) =>
      async () => {
        const { marketingListName } = data;

        const isOneType = Object.keys(submitValue).length === 1;

        turnOnLoading();

        const sendMain = () => {
          if (isOneType) {
            const [attributeType, data] = ObjectShim.entries(submitValue)[0];

            const sendRequest = sendUserAttribute(attributeType);
            return sendRequest(data);
          } else {
            return sendUserGroupAttributes(submitValue);
          }
        };

        const sendAdditional = async () => {
          if (submitAdditionalValues.length) {
            const processedValues = await processAdditionalValues(
              ObjectShim.mergeDeep(userAttributes, submitValue),
              submitAdditionalValues,
              { identifier }
            );
            if (processedValues) {
              return sendAdditionalUserAttributes(ObjectShim.deepen(processedValues), true);
            }
          }
        };

        const subscriptionMarketingList = async () => subscribeUnsubscribeMarketingList({ [marketingListName]: true });

        await sendMain();
        await sendAdditional();
        await sendStatusUpdate();

        await subscriptionMarketingList();

        goToNextQuestion();
      },
    [INPUT_TYPES.updateUser]:
      ({ goToNextQuestion, submitValue, sendUserAttribute, sendStatusUpdate, updateUser, data }) =>
      ({ setSubmitError, errorMessages, value }) =>
      async () => {
        turnOnLoading();

        const sendMain = async () => {
          const [attributeType, data] = ObjectShim.entries(submitValue)[0];

          const sendRequest = sendUserAttribute(attributeType);

          return sendRequest(data);
        };

        const { userField } = data;
        const response = await updateUser({ [userField]: value });

        if (response.errorMsg) {
          turnOffLoading();
          return setSubmitError(errorMessages?.request || response.errorMsg);
        }

        await sendMain();
        sendStatusUpdate();
        goToNextQuestion();
      },
    [INPUT_TYPES.introducer]:
      ({ goToNextQuestion, submitValue, sendUserAttribute, sendStatusUpdate, updateUser }) =>
      ({ setSubmitError, errorMessages, value }) =>
      async () => {
        turnOnLoading();

        const sendMain = brokerName => {
          const [, data] = ObjectShim.entries(submitValue)[0];
          const [, selectedItem] = ObjectShim.entries(data)[0];

          const sendRequest = sendUserAttribute(INTRODUCER_ATTRIBUTE_TYPE);
          return sendRequest({
            [INTRODUCER_ATTRIBUTE_FIELDS.introducer]: selectedItem.value,
            [INTRODUCER_ATTRIBUTE_FIELDS.brokerName]: brokerName
          });
        };

        const response = await updateUser({ introducerCode: value });

        if (response.errorMsg) {
          turnOffLoading();
          return setSubmitError(errorMessages?.request || response.errorMsg);
        }

        await sendMain(response.introducer?.brokerName);

        //Temporary solution, might be replaced when the logic of fetching introducer-specific style is approved
        await setGlobalConfig(value);

        sendStatusUpdate();
        goToNextQuestion();
      },
    [INPUT_TYPES.introducerAutocomplete]:
      ({ goToNextQuestion, submitValue, sendUserAttribute, sendStatusUpdate, updateUser }) =>
      async () => {
        turnOnLoading();

        const [, data] = ObjectShim.entries(submitValue)[0];
        const [, selectedItem] = ObjectShim.entries(data)[0];

        const sendMain = async () => {
          const sendRequest = sendUserAttribute(INTRODUCER_ATTRIBUTE_TYPE);
          return sendRequest({
            [INTRODUCER_ATTRIBUTE_FIELDS.broker]: selectedItem.value,
            [INTRODUCER_ATTRIBUTE_FIELDS.brokerName]: selectedItem.name,
            [INTRODUCER_ATTRIBUTE_FIELDS.introducer]: selectedItem.key
          });
        };

        await updateUser({ introducerCode: selectedItem.key });

        await sendMain();

        //Temporary solution, might be replaced when the logic of fetching introducer-specific style is approved
        await setGlobalConfig(selectedItem.key);

        sendStatusUpdate();
        goToNextQuestion();

        turnOffLoading();
      },
    [INPUT_TYPES.radio]:
      ({
        identifier,
        submitValue,
        submitAdditionalValues,
        data,
        sendUserAttribute,
        sendUserGroupAttributes,
        sendAdditionalUserAttributes,
        goToNextQuestion,
        userAttributes,
        sendStatusUpdate
      }) =>
      async () => {
        const { redirectAfterSave } = data;

        const isOneType = Object.keys(submitValue).length === 1;

        turnOnLoading();

        const sendMain = () => {
          if (isOneType) {
            const entry = ObjectShim.entries(submitValue)[0];
            const [attributeType, data] = entry;

            const sendRequest = sendUserAttribute(attributeType);
            return sendRequest(data);
          } else {
            return sendUserGroupAttributes(submitValue);
          }
        };

        const sendAdditional = async () => {
          if (submitAdditionalValues.length) {
            const processedValues = await processAdditionalValues(
              ObjectShim.mergeDeep(userAttributes, submitValue),
              submitAdditionalValues,
              { identifier }
            );
            if (processedValues) {
              return sendAdditionalUserAttributes(ObjectShim.deepen(processedValues), true);
            }
          }
        };

        // Expanding, onSubmit logic with ability to send specific statusData {activityKey, activityStatusKey} on selected radio item, if set.
        // Used for buying vouchers
        // works only as an alone input (not in GroupInput)
        const sendOptionStatusUpdate = async () => {
          const option = pickRadioOptionByValue(data, submitValue);

          if (option?.statusData) {
            await sendStatusUpdate(option.statusData);
          }
        };

        // redirectAfterSave is mandatory if next question relies on data sent on previous question
        // e.g. pension finder question currently-employed which is right before company-name
        // relies on result of prev question
        if (redirectAfterSave) {
          //waiting for server to respond and redirect after all endpoints are resolved
          await sendMain();
          await sendAdditional();
          await sendOptionStatusUpdate();
          await sendStatusUpdate();
        } else {
          //not waiting for server response, optimistic UI approach
          sendMain();
          sendAdditional();
          sendOptionStatusUpdate();
          sendStatusUpdate();
        }

        goToNextQuestion();
      },
    default:
      ({
        identifier,
        submitValue,
        submitAdditionalValues,
        data,
        sendUserAttribute,
        sendUserGroupAttributes,
        sendAdditionalUserAttributes,
        goToNextQuestion,
        userAttributes,
        sendStatusUpdate,
        validateForm
      }) =>
      async () => {
        const isValid = validateForm();
        if (!isValid) return;

        const { redirectAfterSave } = data;

        const isOneType = Object.keys(submitValue).length === 1;

        turnOnLoading();

        const sendMain = () => {
          if (isOneType) {
            const entry = ObjectShim.entries(submitValue)[0];
            const [attributeType, data] = entry;

            const sendRequest = sendUserAttribute(attributeType);
            return sendRequest(data);
          } else {
            return sendUserGroupAttributes(submitValue);
          }
        };

        const sendAdditional = async () => {
          if (submitAdditionalValues.length) {
            const processedValues = await processAdditionalValues(
              ObjectShim.mergeDeep(userAttributes, submitValue),
              submitAdditionalValues,
              { identifier }
            );
            if (processedValues) {
              return sendAdditionalUserAttributes(ObjectShim.deepen(processedValues), true);
            }
          }
        };

        // redirectAfterSave is mandatory if next question relies on data sent on previous question
        // e.g. pension finder question currently-employed which is right before company-name
        // relies on result of prev question
        if (redirectAfterSave) {
          //waiting for server to respond and redirect after all endpoints are resolved
          await sendMain();
          await sendAdditional();
          await sendStatusUpdate();
        } else {
          //not waiting for server response, optimistic UI approach
          sendMain();
          sendAdditional();
          sendStatusUpdate();
        }

        goToNextQuestion();
      }
  });
});
