// eslint-disable-next-line import/no-webpack-loader-syntax
import workerBase from 'workerize-loader!../workers/evaluate-condition';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { StringShim } from '@packages/helpers/core/shims/string-shim';
import { interpolateRegex, interpolateRegexV2 } from '../regex';

const worker = workerBase();
/**
 *
 * @param {string} condition - stringified display condition from admin
 * @param {object} userState - state, including spreaded userAttributes and aktivity = statusData.plainStatuses
 * @return {Promise<boolean|*>}
 */

// You must wrap strings in admin console into ``
// `${goal.propertyPrise}` >= 1500000
// `${goal.location}` !== `London`
export const evaluateCondition = async (condition, userState) => {
  if (!StringShim.isString(condition)) {
    return Boolean(condition);
  }

  try {
    const expression = condition.replace(interpolateRegex, (_, name) => {
      return ObjectShim.getNested(userState, name);
    });

    return await worker.evaluate(expression);
  } catch {
    return false;
  }
};

const interpolateString = (template, userState) => {
  const interpolateRegexToUse = getInterpolateRegex(template);

  return template.replace(interpolateRegexToUse, (_, name) => {
    return ObjectShim.getNested(userState, name);
  });
};

/**
 * Evaluates a string condition using user state.
 *
 * @param {string} condition - stringified string condition from admin
 * @param {object} userState - state, including spreaded userAttributes and aktivity = statusData.plainStatuses
 * @return {Promise<string|*>}
 */
// Example of condition:
// "('${user.firstName}' == 'Ivan')"
// "('%userAttribute.user.firstName%' == 'Petro')"
// "('${userAttribute.param}' == 'someValue' ? 'value1' : 'value2')"
// "('%userAttribute.param%' == 'someValue' ? 'value1' : 'value2')"
export const evaluateStringCondition = (condition, userState) => {
  if (!condition) {
    return true;
  }

  try {
    if (!StringShim.isString(condition)) {
      return Boolean(condition);
    }

    const expression = interpolateString(condition, userState);

    if (expression === condition) {
      return condition;
    }
    return evaluateFunction(expression);
  } catch (error) {
    return '';
  }
};

function getInterpolateRegex(condition) {
  if (interpolateRegexV2.test(condition)) {
    return interpolateRegexV2;
  }
  return interpolateRegex;
}

function evaluateFunction(expression) {
  // eslint-disable-next-line no-new-func
  return new Function(`try { return (${expression}) } catch(e) { return \`Syntax error \${e}\` }`)();
}
