import { compose, lifecycle, withHandlers, withPropsOnChange, withState } from 'recompose';
import { connect } from 'react-redux';
import { parse } from 'query-string';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { getQuestions } from '../../../store/reducers/questions';
import { evaluateCondition } from '../../../helpers/parcers/parce-condition';
import { withEmbeddedContext } from '../../../components/layout/with-embedded-context';
import { setDisableNavigation, resetDisableNavigation } from '../../../store/reducers/navigation';
import { EDIT_MODE_WITH_SKIP } from '../../../helpers/navigation';
import { SET_PAGE_STATE_SUCCESS } from '../../../store/reducers/application';
import { withDisplayConditionState } from '../../../helpers/display-condition/hocs/with-display-condition-state';
import { fromMode, getLocationFromModeState } from '../../../helpers/navigation/routing';
import { parseMessage } from '../helpers/parse-message';

const mapStateToProps = (state, { activity, slug }) => ({
  question: state.questions.questions?.[activity]?.[slug],
  loadingQuestions: state.questions.loading,
  questionsExist: ObjectShim.has(state.questions.questions, activity),
  userAttributes: state.userAttributes,
  disableNavigation: state.navigation.disableNavigation
});

export const withQuestion = compose(
  withPropsOnChange(
    ['activity', 'slug', 'identifier', 'match'],
    ({ activity, slug, identifier, match: { params } }) => ({
      activity: activity ?? params.activity,
      slug: slug ?? params.slug,
      identifier: identifier ?? params.identifier
    })
  ),
  connect(mapStateToProps, { getQuestions, setDisableNavigation, resetDisableNavigation }),
  withDisplayConditionState,
  withState('questionMessage', 'setMessage', null),
  withState('currentQuestion', 'setQuestion', null),
  withState('isReady', 'setReady', false),
  withPropsOnChange(['question', 'currentQuestion'], ({ currentQuestion, question }) => ({
    currentQuestion: currentQuestion ?? question
  })),
  withEmbeddedContext,
  withHandlers({
    skipQuestion:
      ({ question, history, location, identifier, userState }) =>
      async () => {
        const mode = location.search && parse(location.search).mode;

        //Skip question if there is condition and it is not edit mode
        //can proceed with 'edit-with-skip' mode
        if ((!mode || mode === EDIT_MODE_WITH_SKIP) && question?.skipQuestion) {
          const skipCondition = await evaluateCondition(userState, question.skipQuestion.condition, {
            identifier
          });

          const nextPageLink = `${question.skipQuestion.nextPageLink}${identifier ? '/' + identifier : ''}`;

          if (skipCondition) {
            const origin = getLocationFromModeState(location.state);
            history.replace(fromMode(nextPageLink, origin));

            return true;
          }
        }

        return false;
      },
    setQuestionMessage:
      ({ question, message, setMessage, identifier, userAttributes, turnOffLoading }) =>
      async () => {
        const questionMessage = await parseMessage(userAttributes, message ?? question?.text, { identifier });

        setMessage(questionMessage);
        turnOffLoading();
      }
  }),
  withHandlers({
    onQuestionChange:
      ({
        skipQuestion,
        setQuestionMessage,
        setLastQuestion,
        location,
        setQuestion,
        question,
        activity,
        isEmbedded,
        postResizeMessage,
        setDisableNavigation,
        setReady
      }) =>
      async () => {
        const isSkipped = await skipQuestion();

        if (!isSkipped) {
          // Loading is needed to prevent form been shown before the validation schema is set
          // The issue was in pressing back btn in the browser, when the previous schema was applied to form
          setReady(false);
          setQuestion(question);
          await setQuestionMessage();

          //allows do disable navigation in header
          setDisableNavigation({ disableNavigation: !!question.disableNavigation });

          setLastQuestion({ activity, lastQuestion: location.pathname });

          if (isEmbedded) {
            postResizeMessage();
          }

          setReady(true);
        }
      }
  }),
  lifecycle({
    async componentDidMount() {
      const { getQuestions, activity, setPageLoadedState } = this.props;
      await getQuestions(activity);
      setPageLoadedState(SET_PAGE_STATE_SUCCESS);
    },
    async componentDidUpdate(prevProps) {
      const {
        question,
        onQuestionChange,
        message,
        setQuestionMessage,
        questionsExist,
        activity,
        getQuestions,
        loadingQuestions
      } = this.props;

      if (!questionsExist && !loadingQuestions) {
        await getQuestions(activity);
      }

      if (prevProps.question !== question) {
        await onQuestionChange();
      } else if (prevProps.message !== message) {
        await setQuestionMessage();
      }
    },
    componentWillUnmount() {
      const { resetDisableNavigation } = this.props;

      resetDisableNavigation();
    }
  })
);
