import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { PromiseShim } from '@packages/helpers/core/shims/promise-shim';
import { setNavThemedPages } from '../../store/reducers/navigation';
import { getConsentList } from '../../store/reducers/consent-list';
import { getAffiliateRelatedStaticData } from '../../store/reducers/static-data';
import { getActivitiesList, getStatusData } from '../../store/reducers/status-data';
import { setGlobalConfig } from '../../api/config';
import { NativeAction } from '../../services/native-action';
import { UserService } from '../../services/user-service';
import { MarketingInfoService } from '../../services/marketing-info-serice';
import { getFeedbackAndFavorites } from '../../store/reducers/content-interaction';
import { getValidations } from '../../store/reducers/validations';
import { getUserAttributes } from '../../store/reducers/user-attributes/actions';
import { withTrackingHandlers } from '../../helpers/hocs/with-tracking-handlers';
import { TRACKING_STATUSES } from '../../helpers/constants';
import { TrackingService } from '../../services/tracking-service';
import { StorageListenerService } from '../../services/storage-listener-service';
import { withAutoLogoutHandlers } from './with-auto-logout';

export const withDefaultApp = compose(
  connect(null, {
    setNavThemedPages,
    getConsentList,
    getAffiliateRelatedStaticData,
    getStatusData,
    getFeedbackAndFavorites,
    getValidations,
    getActivitiesList,
    getUserAttributes
  }),
  withTrackingHandlers,
  withAutoLogoutHandlers,
  lifecycle({
    async componentDidMount() {
      const {
        turnOffLoading,
        setNavThemedPages,
        processUserData,
        location,
        getConsentList,
        getAffiliateRelatedStaticData,
        getStatusData,
        getFeedbackAndFavorites,
        replacePathnameExtraSlash,
        getValidations,
        getUserAttributes,
        sendTrackingData,
        initializeSessionTimer,
        getActivitiesList
      } = this.props;

      replacePathnameExtraSlash(location);

      const response = await processUserData();

      await setGlobalConfig(response?.introducerCode);

      UserService.initialize(response);

      NativeAction.initialize();

      initializeSessionTimer();

      setNavThemedPages();

      // List of promises to be resolved after user created and configuration uploaded
      const promises = [getConsentList(location), getAffiliateRelatedStaticData(), getActivitiesList()];

      if (!UserService.hasAccount()) {
        promises.push(getStatusData(), getFeedbackAndFavorites(), getValidations(), getUserAttributes());
        sendTrackingData(TRACKING_STATUSES.APP_INIT, {
          timestamp: TrackingService.getTrackerTimestamp(TRACKING_STATUSES.APP_INIT)
        });
      }

      await PromiseShim.allSettled(promises);

      UserService.track();

      turnOffLoading();
    },
    componentDidUpdate(prevProps) {
      const { location, replacePathnameExtraSlash, sendTrackingData } = this.props;
      if (prevProps.location.pathname !== location.pathname) {
        replacePathnameExtraSlash(location.pathname);

        sendTrackingData(TRACKING_STATUSES.LOCATION_CHANGED, {
          prevLocation: prevProps.location.pathname,
          currLocation: location.pathname
        });
      }

      MarketingInfoService.processURLAttributes(location.search);
    },
    componentWillUnmount() {
      StorageListenerService.clear();
    }
  })
);
