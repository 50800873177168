import { compose, withHandlers } from 'recompose';
import { isNil, isUndefined } from '@packages/helpers/core/common';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { getOnChangeValidationError } from '../../helpers/validation-handlers';
import { getInputStateIdentifier } from '../../helpers/shemas-v2';
import { mapHandlerByType } from '../../helpers/map-handler-by-input-type';
import { INPUT_TYPES } from '../../helpers/constants';
import { isInputValueOptional } from '../../../../helpers/validation/schema-keys';
import { validateCheckbox } from '../../helpers/checkbox-validation';
import { isValidPropertyAddress } from '../../helpers/property-address';
import { buildNestedAttributeValue, getUserAttributeTypeParts } from '../../helpers/nested-attribute';

const withValidator = withHandlers({
  getValidationError:
    ({ userAttributeType, userAttributeField, validationSchema, submitValue, identifier }) =>
    value => {
      const { attributeType } = getUserAttributeTypeParts(userAttributeType);
      const updateValue = {
        [attributeType]: buildNestedAttributeValue(
          {
            userAttributeType,
            userAttributeField,
            identifier
          },
          { value }
        )
      };

      const context = ObjectShim.mergeDeep(submitValue, updateValue);

      return getOnChangeValidationError({
        schema: validationSchema,
        path: getInputStateIdentifier(userAttributeType, identifier, userAttributeField),
        value: updateValue,
        context
      });
    }
});

// TODO: Will be good to move validation for the rest inputs here as well
export const withValidationHandler = compose(
  withHandlers({
    setFormDisabledItem: ({ setFormDisabledItems, ...props }) => setFormDisabledItems(props)
  }),
  withValidator,
  withHandlers(props => {
    const { type } = props;

    return mapHandlerByType(
      type,
      'validateValue'
    )({
      [INPUT_TYPES.checkbox]:
        ({ setFormDisabledItem, requiredAll, items, max, min, defaultValue, isOptional, errorMessages }) =>
        value => {
          if (isInputValueOptional(isOptional)) return setFormDisabledItem(false);

          if (isNil(value)) return setFormDisabledItem(true);

          const error = validateCheckbox(value, { max, min, requiredAll, items, defaultValue, errorMessages });

          setFormDisabledItem(!!error);
          return error;
        },
      [INPUT_TYPES.radio]:
        ({ setFormDisabledItem, items, plainValue, isOptional }) =>
        value => {
          if (isInputValueOptional(isOptional)) return setFormDisabledItem(false);

          const isDisabledItem = plainValue
            ? isNil(value) ||
              !items.some(item => {
                //@TODO: its temp solution, need to refactor, discuss with FE team
                const isComplexValue = typeof value === 'object';
                const hasNamedValue = value?.[item.name];

                return isComplexValue && hasNamedValue && 'text' in hasNamedValue ? true : value === item.value;
              })
            : isNil(value) || !Object.values(value).some(item => item.checked);

          setFormDisabledItem(isDisabledItem);
        },
      [INPUT_TYPES.segmentedButton]:
        ({ setFormDisabledItem, isOptional, items, plainValue }) =>
        value => {
          if (isInputValueOptional(isOptional)) return setFormDisabledItem(false);

          const isDisabledItem = plainValue
            ? isNil(value) || !items.some(item => item.value === value || item.label === value)
            : isNil(value) || !Object.values(value).some(item => item.checked);
          setFormDisabledItem(isDisabledItem);
        },
      [INPUT_TYPES.select]:
        ({ setFormDisabledItem, isOptional }) =>
        value => {
          if (isInputValueOptional(isOptional)) return setFormDisabledItem(false);

          setFormDisabledItem(isNil(value));
        },
      [INPUT_TYPES.address]:
        ({ setFormDisabledItem, isOptional }) =>
        value => {
          if (isInputValueOptional(isOptional)) return setFormDisabledItem(false);

          const isValid = isValidPropertyAddress(value);
          setFormDisabledItem(!isValid);
        },
      [INPUT_TYPES.propertyAddress]:
        ({ setFormDisabledItem, isOptional }) =>
        value => {
          if (isInputValueOptional(isOptional)) return setFormDisabledItem(false);

          setFormDisabledItem(isUndefined(value));
        },
      [INPUT_TYPES.wheel]:
        ({ setFormDisabledItem, isOptional }) =>
        value => {
          if (isInputValueOptional(isOptional)) return setFormDisabledItem(false);

          setFormDisabledItem(isNil(value));
        },
      default:
        ({ setFormDisabledItem, getValidationError }) =>
        value => {
          const errorMessage = getValidationError(value);
          setFormDisabledItem(Boolean(errorMessage));

          return errorMessage;
        }
    });
  })
);
