import React from 'react';
import { withPropsOnChange, compose } from 'recompose';
import classnames from 'classnames';
import styles from '../../../styles/components/checkbox.module.scss';
import { AffiliateIcon } from '../../icons/affiliate-icon';
import { withAnchor, withContainer, withLabel, withOptionLabel, withOptionRowClick } from '../input-field';
import { omitProps } from '../../../helpers/hocs/omit-props';

export const Input = React.memo(({ className, inputRef, ...props }) => (
  <input ref={inputRef} type='checkbox' className={classnames(styles.input, className, 'thrown-away')} {...props} />
));

const Component = React.memo(
  ({ id, icon = <AffiliateIcon name='checkbox-done' />, checked = false, pulse, ...props }) => (
    <div className={props.className}>
      <Input id={id} className={styles.field} checked={checked} {...props} />
      <div className={classnames(styles.icon, { pulse })}>{icon}</div>
    </div>
  )
);

const CONSTRAINTS = ['labelId'];

export const Checkbox = compose(withAnchor, withLabel, omitProps(CONSTRAINTS))(Component);
export const InputCheckbox = compose(
  withAnchor,
  withContainer,
  withOptionRowClick,
  // These are the styles which will be applied to the label tag
  withPropsOnChange(['styling', 'className'], ({ styling, className }) => ({
    className: classnames({ [styles.label]: !styling }, { [styles[styling]]: styling }, className)
  })),
  withOptionLabel({
    layout: styles.container
  })
)(Component);
