import { useMemo } from 'react';

const pickFormProps = (props, propsToPick) => {
  const formProps = {};
  propsToPick.forEach(prop => {
    formProps[prop] = props[prop];
  });

  return formProps;
};
export const usePropPicker = (props, keys) => {
  const pickedProps = useMemo(() => pickFormProps(props, keys), [props, keys]);

  return { pickedProps };
};
