import React from 'react';
import { compose, branch, renderComponent, defaultProps } from 'recompose';
import { isNil } from '@packages/helpers/core/common';
import { createCustomPageProps } from '../../helpers/utils';
import { Question as QuestionLayout } from '../../templates/questions/question-template';
import { Loader } from '../../components/with-loader';
import { withMetaTags } from '../../components/meta-tags';
import { SectionPageTemplate } from '../../templates/questions/section-page-template';
import { withPageLoadingLifecycle } from '../../components/layout/content/with-native-loading-state';
import { withBarrier } from '../account/pages/barrier/barrier';
import { withQuestion } from './hocs/with-question';
import { withLastQuestion } from './hocs/with-last-question';
import { QuestionForm } from './components/QuestionForm';
import { withQuestionContextProvider } from './context/hocs/withQuestionContextProvider';
import { withQuestionContext } from './context/hocs/withQuestionContext';

const QuestionTemplate = React.memo(
  ({
    question,
    questionMessage,
    location: { pathname },
    identifier,
    children,
    onSubmit,
    currentQuestion,
    disableNavigation,
    isReady
  }) => (
    <QuestionLayout
      atid={createCustomPageProps(pathname, '/').full}
      innerHtml={questionMessage}
      slotName={pathname}
      hideCloseButton={disableNavigation}
      {...currentQuestion}
    >
      {/*This allows to hide question inputs if there is no question message to avoid flickering, except empty string, as some questions can not have it*/}
      {!isNil(questionMessage) ? (
        <>
          {children ? (
            children(question)
          ) : (
            <QuestionForm
              questionInputs={currentQuestion.questionInputs}
              onSubmit={onSubmit}
              identifier={identifier}
              isQuestionReady={isReady}
            />
          )}
        </>
      ) : null}
    </QuestionLayout>
  )
);

const SEO_PAGE_DATA_NAME = 'default';

export const Question = compose(
  withBarrier,
  defaultProps({ seoName: SEO_PAGE_DATA_NAME }),
  withMetaTags,
  withQuestionContextProvider,
  withQuestionContext,
  withBarrier,
  withLastQuestion,
  withPageLoadingLifecycle,
  withQuestion,
  branch(({ question }) => !question, renderComponent(Loader)),
  branch(({ currentQuestion: { type } }) => type === 'questionSectionPage', renderComponent(SectionPageTemplate))
)(QuestionTemplate);
