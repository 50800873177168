import * as yup from 'yup';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import { StringShim } from '@packages/helpers/core/shims/string-shim';
import { evaluateStringCondition } from '../../../helpers/checklist/display-condition';
import { isInputValueOptional } from '../../../helpers/validation/schema-keys';
import { evaluate } from '../../../helpers/workers/evaluate-message';
import { parseDateBoundary } from '../../../helpers/validation/date-input';
import { isEmail } from '../../../helpers/validation/account';
import { phoneNumberRegex } from '../../../helpers/regex';
import { INPUT_TYPES } from './constants';
import { applyAdditionalRules } from './additional-rules';

dayjs.extend(customParseFormat);
dayjs.extend(duration);

/**
 * Get the identifier for the input state
 * @param args -  Arguments strings to join. The order of the strings is important
 * @return {string}
 */
export const getInputStateIdentifier = (...args) => args.filter(Boolean).join('.');

const DEFAULT_ERROR_MESSAGES = {
  min: '',
  max: '',
  base: ''
};

const generateInputBaseRule = (type, { errorMessages = DEFAULT_ERROR_MESSAGES, max, min, format, isOptional }) => {
  switch (type) {
    case INPUT_TYPES.number: {
      return yup
        .number()
        .typeError(errorMessages.base)
        .min(min, errorMessages.min)
        .max(max, errorMessages.max)
        .nullable();
    }
    case INPUT_TYPES.text:
    case INPUT_TYPES.updateUser: {
      return yup
        .string()
        .typeError(errorMessages.base)
        .min(min, errorMessages.min)
        .max(max, errorMessages.max)
        .nullable();
    }
    case INPUT_TYPES.email: {
      return yup
        .string()
        .test({
          name: 'validEmail',
          message: errorMessages.base,
          test: function (value) {
            return value ? isEmail(value) : true;
          }
        })
        .min(min, errorMessages.min)
        .max(max, errorMessages.max)
        .nullable();
    }
    case INPUT_TYPES.date: {
      return yup
        .string()
        .transform(value => {
          return !StringShim.isString(value) || value === 'NaN' ? '' : value;
        })
        .test({
          name: 'validDate',
          message: `Invalid date! The date format should be ${format}`,
          test: function (value) {
            if (!value) return true;

            const date = dayjs(value.toString(), format, true);

            return date.isValid();
          }
        })
        .test({
          name: 'minDate',
          message: errorMessages.min,
          test: function (value) {
            if (!value || !min) return true;

            const date = parseDateBoundary(value, format);
            const minimum = parseDateBoundary(min);

            return date.isAfter(minimum) || date.isSame(minimum);
          }
        })
        .test({
          name: 'maxDate',
          message: errorMessages.max,
          test: function (value) {
            if (!value || !max) return true;

            const date = parseDateBoundary(value, format);
            const maximum = parseDateBoundary(max);

            return date.isBefore(maximum) || date.isSame(maximum);
          }
        })
        .nullable();
    }
    case INPUT_TYPES.phoneNumber: {
      return yup
        .string()
        .test({
          name: 'validPhoneNumber',
          message: errorMessages.base,
          test: function (value) {
            if (isInputValueOptional(isOptional, value)) return true;

            return phoneNumberRegex.test('+' + value);
          }
        })
        .test({
          name: 'minLength',
          message: errorMessages.min,
          test: function (value) {
            if (!value) return true;
            const valueAfterTrim = value.toString().trim();

            return valueAfterTrim.length >= min;
          }
        })
        .test({
          name: 'maxLength',
          message: errorMessages.max,
          test: function (value) {
            if (!value) return true;
            const valueAfterTrim = value.toString().trim();

            return valueAfterTrim.length <= max;
          }
        })
        .nullable();
    }
    case INPUT_TYPES.introducer: {
      return yup.string().min(min, errorMessages.min).max(max, errorMessages.max);
    }
    default:
      return null;
  }
};

const mergeValidationContext = (validationContext, externalContext) => {
  const updatedContext = ObjectShim.mergeDeep(externalContext, validationContext.options.context);
  ObjectShim.setNested(updatedContext, validationContext.path, validationContext.originalValue);
  return updatedContext;
};

const generateInputCustomRules = (baseRule, inputConfig, context) => {
  const { customValidation, displayCondition } = inputConfig;
  if (!customValidation || !baseRule) return baseRule;

  return customValidation.reduce((rule, { rule: validationRule, errorMessage }) => {
    return rule.test({
      name: validationRule,
      test: function (value, validationContext) {
        // Merge the context with the current form value
        const updatedContext = mergeValidationContext(validationContext, context);

        // If input is not visible, it's valid
        const isVisible = evaluateStringCondition(displayCondition, updatedContext);
        if (!isVisible) {
          return true;
        }

        const isRuleValid = evaluateStringCondition(validationRule, updatedContext);

        if (!isRuleValid) {
          return validationContext.createError({ message: evaluate(errorMessage, { userAttributes: updatedContext }) });
        }

        return true;
      }
    });
  }, baseRule); // Start chaining from the baseRule
};

const addRequiredRuleForVisibleInputs = (rule, inputConfig, context) => {
  if (!rule) return null;
  const { isOptional, displayCondition, errorMessages } = inputConfig;

  if (displayCondition) {
    return rule.test({
      name: displayCondition,
      message: errorMessages.base,
      test: function (value, validationContext) {
        // Evaluate visibility condition using external context
        const updatedContext = mergeValidationContext(validationContext, context);
        const isVisible = evaluateStringCondition(displayCondition, updatedContext);

        // If the field is visible and not optional, it must have a value
        if (isVisible && !isOptional) {
          return value !== undefined && value !== null && value !== '';
        }

        // Otherwise, it's valid
        return true;
      }
    });
  }

  if (!isOptional) {
    return rule.required(errorMessages.base);
  }

  return rule;
};

export const generateInputRules = (type, inputConfig, state) => {
  const { errorMessages, max, min, isOptional, format, additionalRules } = inputConfig;
  let baseRule = generateInputBaseRule(type, { errorMessages, max, min, isOptional, format });

  baseRule = addRequiredRuleForVisibleInputs(baseRule, inputConfig, state.userAttributes);

  if (additionalRules) {
    baseRule = applyAdditionalRules({
      type,
      currentValidation: baseRule,
      rulesToApply: additionalRules,
      extraInputData: {
        format
      }
    });
  }

  return generateInputCustomRules(baseRule, inputConfig, state.userAttributes);
};
