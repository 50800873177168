import { compose, withHandlers, withPropsOnChange, withState } from 'recompose';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { mapHandlerByType } from '../../helpers/map-handler-by-input-type';
import { getInputStateIdentifier } from '../../helpers/shemas-v2';

export const withErrorHandler = compose(
  withHandlers({
    setFormErrorItem: ({ setFormErrorItems, ...props }) => setFormErrorItems(props)
  }),
  withState('error', 'setError', null),
  withHandlers(props => {
    const { type } = props;

    return mapHandlerByType(
      type,
      'onError'
    )({
      default:
        ({ setFormDisabledItem, setFormErrorItem, setError }) =>
        error => {
          setError(error);
          setFormErrorItem(error);
          setFormDisabledItem(!!error);
        }
    });
  }),
  withPropsOnChange(['formErrors'], ({ userAttributeType, userAttributeField, error, formErrors, setError }) => {
    // Make sure, there is no input error which is set on input level
    if (!error && ObjectShim.isEmpty(formErrors)) return;
    const inputName = getInputStateIdentifier(userAttributeType, userAttributeField);

    if (formErrors[inputName]) {
      setError(formErrors[inputName]);
    }
  })
);
