import React from 'react';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { branch, compose, lifecycle, renderNothing, withHandlers, withState } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Input } from '../input';
import { INPUT_TYPES } from '../helpers/constants';
import { generateInputRules } from '../helpers/shemas-v2';
import { dynamicValidation } from '../helpers/dynamic-validations';
import { getUserAttributeTypeParts, isInputNested } from '../helpers/nested-attribute';
import { withQuestionContext } from '../context/hocs/withQuestionContext';

const handleUserAttributeType = ({ acc, input, params, generatedRule }) => {
  const { match } = params;
  const { data } = input;
  const { userAttributeType, userAttributeField } = data;
  const { identifier } = match.params;
  const { attributeType, name } = getUserAttributeTypeParts(userAttributeType);

  if (isInputNested(name, identifier)) {
    return {
      ...acc,
      [attributeType]: yup.object({
        ...acc[attributeType]?.fields,
        [name]: yup.object({
          ...acc[attributeType]?.[name]?.fields,
          [identifier]: yup.object({
            ...acc[attributeType]?.[name]?.[identifier]?.fields,
            [userAttributeField]: generatedRule
          })
        })
      })
    };
  }

  return {
    ...acc,
    [attributeType]: yup.object({
      ...acc[attributeType]?.fields,
      [userAttributeField]: generatedRule
    })
  };
};

const inputReducer = (acc, input, params) => {
  if (input.type === INPUT_TYPES.groupParams) {
    return {
      ...acc,
      ...input.items.reduce((acc, input) => inputReducer(acc, input, params), acc)
    };
  }

  const { state, match } = params;

  const { data, type } = input;

  const dynamicValidationRules = dynamicValidation(state, match, data);

  const generatedRule = generateInputRules(type, { ...data, ...dynamicValidationRules }, state);

  if (!generatedRule) return acc;

  return handleUserAttributeType({ acc, input, params, generatedRule });
};

const FormComponent = React.memo(({ questionInputs, identifier, onSubmit }) => (
  <>
    {questionInputs
      .sort((a, b) => a.order - b.order)
      .map(({ id, type, data, items }) => (
        <Input identifier={identifier} key={id} type={type} data={data} items={items} onSubmit={onSubmit} />
      ))}
  </>
));

export const QuestionForm = compose(
  connect(state => ({ state })),
  withRouter,
  withQuestionContext,
  withState('isReady', 'setIsReady', ({ isQuestionReady }) => isQuestionReady),
  withHandlers({
    setSchema:
      ({ questionInputs, setIsReady, setValidationSchema, state, match }) =>
      () => {
        setIsReady(false);
        const formSchema = yup.object({
          ...questionInputs.reduce((acc, input) => inputReducer(acc, input, { state, match }), {})
        });

        setValidationSchema(formSchema);
        setIsReady(true);
      },
    resetSchema:
      ({ setIsReady, setValidationSchema }) =>
      () => {
        setIsReady(false);
        setValidationSchema(null);
      }
  }),
  lifecycle({
    componentDidMount() {
      const { setSchema } = this.props;
      setSchema();
    },
    componentDidUpdate(prevProps) {
      const { setSchema, location, resetSchema, validationSchema, questionInputs } = this.props;
      if (prevProps.location.pathname !== location.pathname) {
        resetSchema();
      }

      if (!validationSchema || prevProps.questionInputs !== questionInputs) {
        setSchema();
      }
    },
    componentWillUnmount() {
      const { resetSchema } = this.props;

      resetSchema();
    }
  }),
  branch(({ isReady, isQuestionReady }) => !isReady || !isQuestionReady, renderNothing)
)(FormComponent);
