import { compose, withHandlers, withPropsOnChange, withState } from 'recompose';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { withInputRenderCondition } from '../../../templates/checklist/with-input-render-condition';
import { getInputStateIdentifier } from '../helpers/shemas-v2';
import { getOnSubmitValidationError } from '../helpers/validation-handlers';
import { buildNestedAttributeValue } from '../helpers/nested-attribute';
import { withSubmitHandlers } from './with-handlers';

const checkDisableConditions = ({ disabledItems, formErrors }) => {
  const isDisabled = Object.values(disabledItems).some(isDisabled => isDisabled);

  if (!ObjectShim.isEmpty(formErrors)) {
    return Object.values(formErrors).some(error => error) || isDisabled;
  }

  return isDisabled;
};

const withFormValidation = compose(
  withHandlers({
    getFormErrors:
      ({ submitValue, validationSchema }) =>
      () => {
        const errors = getOnSubmitValidationError({
          schema: validationSchema,
          value: submitValue,
          context: submitValue
        });

        return errors;
      }
  }),
  withHandlers({
    validateForm:
      ({ getFormErrors, setFormErrors, setDisabledItems }) =>
      () => {
        const errors = getFormErrors();

        setFormErrors(errors);
        const disabledItems = Object.keys(errors).reduce((acc, key) => ({ ...acc, [key]: !!errors[key] }), {});

        setDisabledItems(disabledItems);

        return ObjectShim.isEmpty(errors);
      }
  })
);

export const withForm = compose(
  withState('submitValue', 'setSubmitValue', {}),
  withState('submitAdditionalValues', 'setSubmitAdditionalValues', []),
  withState('disabledItems', 'setDisabledItems', {}),
  withState('formErrors', 'setFormErrors', {}),
  withPropsOnChange(['disabledItems', 'formErrors'], ({ disabledItems, formErrors }) => ({
    disabled: checkDisableConditions({ disabledItems, formErrors })
  })),
  withHandlers({
    setFormValues:
      ({ setSubmitValue }) =>
      ({ userAttributeField, userAttributeValue, staticPayload, userAttributeType, attributeType, identifier }) =>
      value => {
        if (userAttributeType) {
          setSubmitValue(submitValue => ({
            ...submitValue,
            [attributeType]: buildNestedAttributeValue(
              { userAttributeType, userAttributeField, identifier },
              { value: value ?? userAttributeValue, staticPayload },
              submitValue[attributeType]
            )
          }));
        }
      },
    setFormDisabledItems:
      ({ setDisabledItems }) =>
      ({ attributeType, userAttributeField }) =>
      isDisabled => {
        setDisabledItems(state => ({
          ...state,
          [getInputStateIdentifier(attributeType, userAttributeField)]: isDisabled
        }));
      },
    setFormErrorItems:
      ({ setFormErrors }) =>
      ({ attributeType, userAttributeField }) =>
      error => {
        setFormErrors(state => ({
          ...state,
          [getInputStateIdentifier(attributeType, userAttributeField)]: error
        }));
      },
    setSubmitAdditionalValues:
      ({ setSubmitAdditionalValues }) =>
      additionalValues => {
        setSubmitAdditionalValues(prevAdditionalValues => [...prevAdditionalValues, ...additionalValues]);
      }
  }),
  withFormValidation,
  withSubmitHandlers,
  withInputRenderCondition
);
